@import "src/core/scss/variables";

.checkbox {
	display: block;
	position: relative;
	padding-left: 21px;
	cursor: pointer;
	font-size: $text-xl;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkbox-mark {
		position: absolute;
		top: 0;
		left: 0;
		height: 21px;
		width: 21px;
		background-color: $white-background;
		border: 1.5px solid $dark-icon;
		border-radius: 4px;

		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 7px;
			top: 3px;
			width: 5px;
			height: 10px;
			border: solid $white-background;
			border-width: 0 3px 3px 0;
			transform: rotate(45deg);
		}
	}

	input:checked ~ .checkbox-mark {
		background-color: $black-primary;
		border: 1.5px solid $black-primary;

		&:after {
			display: block;
		}
	}
}
