@import "src/core/scss/variables";

.remark {
	line-height: 120%;
	position: relative;
	padding-left: 21px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&:before {
		content: '';
		width: 5px;
		height: 100%;
		background: $primary-background;
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 5px;
	}

	.remark-item {
		line-height: 19.2px;
		margin: 0;
	}

	&.remark-danger {

		&:before {
			background: $danger-border;
		}
	}
}
