@import "mixins/fonts-load";

/*
	100 - Thin
	200 - Extra Light (Ultra Light)
	300 - Light
	400 - Normal
	500 - Medium
	600 - Semi Bold (Demi Bold)
	700 - Bold
	800 - Extra Bold (Ultra Bold)
	900 - Black (Heavy)
*/

/* SF Pro */
@include font('sf-pro-ex', normal, 100, '/assets/fonts/Sf-Pro/SFProDisplay-Thin.woff2');
@include font('sf-pro-ex', italic, 100, '/assets/fonts/Sf-Pro/SFProDisplay-ThinItalic.woff2');

@include font('sf-pro-ex', normal, 200, '/assets/fonts/Sf-Pro/SFProDisplay-Ultralight.woff2');
@include font('sf-pro-ex', italic, 200, '/assets/fonts/Sf-Pro/SFProDisplay-UltralightItalic.woff2');

@include font('sf-pro-ex', normal, 300, '/assets/fonts/Sf-Pro/SFProDisplay-Light.woff2');
@include font('sf-pro-ex', italic, 300, '/assets/fonts/Sf-Pro/SFProDisplay-LightItalic.woff2');

@include font('sf-pro-ex', normal, 400, '/assets/fonts/Sf-Pro/SFProDisplay-Regular.woff2');
@include font('sf-pro-ex', italic, 400, '/assets/fonts/Sf-Pro/SFProDisplay-RegularItalic.woff2');

@include font('sf-pro-ex', normal, 500, '/assets/fonts/Sf-Pro/SFProDisplay-Medium.woff2');
@include font('sf-pro-ex', italic, 500, '/assets/fonts/Sf-Pro/SFProDisplay-MediumItalic.woff2');

@include font('sf-pro-ex', normal, 600, '/assets/fonts/Sf-Pro/SFProDisplay-Semibold.woff2');
@include font('sf-pro-ex', italic, 600, '/assets/fonts/Sf-Pro/SFProDisplay-SemiboldItalic.woff2');

@include font('sf-pro-ex', normal, 700, '/assets/fonts/Sf-Pro/SFProDisplay-Bold.woff2');
@include font('sf-pro-ex', italic, 700, '/assets/fonts/Sf-Pro/SFProDisplay-BoldItalic.woff2');

@include font('sf-pro-ex', normal, 800, '/assets/fonts/Sf-Pro/SFProDisplay-Heavy.woff2');
@include font('sf-pro-ex', italic, 800, '/assets/fonts/Sf-Pro/SFProDisplay-HeavyItalic.woff2');

@include font('sf-pro-ex', normal, 900, '/assets/fonts/Sf-Pro/SFProDisplay-Black.woff2');
@include font('sf-pro-ex', italic, 900, '/assets/fonts/Sf-Pro/SFProDisplay-BlackItalic.woff2');


/* Gordita */
@include font('Gordita', normal, 300, '/assets/fonts/Gordita/Gordita-Light.woff2');
@include font('Gordita', italic, 300, '/assets/fonts/Gordita/Gordita-LightItalic.woff2');

@include font('Gordita', normal, 400, '/assets/fonts/Gordita/Gordita-Regular.woff2');
@include font('Gordita', italic, 400, '/assets/fonts/Gordita/Gordita-Italic.woff2');

@include font('Gordita', normal, 500, '/assets/fonts/Gordita/Gordita-Medium.woff2');
@include font('Gordita', italic, 500, '/assets/fonts/Gordita/Gordita-MediumItalic.woff2');

@include font('Gordita', normal, 700, '/assets/fonts/Gordita/Gordita-Bold.woff2');
@include font('Gordita', italic, 700, '/assets/fonts/Gordita/Gordita-BoldItalic.woff2');

@include font('Gordita', normal, 900, '/assets/fonts/Gordita/Gordita-Black.woff2');
@include font('Gordita', italic, 900, '/assets/fonts/Gordita/Gordita-BlackItalic.woff2');
