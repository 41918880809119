@import "src/core/scss/variables";

.badge {
	padding: 15px;
	border-radius: 8px;
	background: transparent;
	border-width: 1px;
	border-style: solid;

	p {
		line-height: 1.2;
		margin-bottom: 0;
	}

	&.badge-error {
		color: $danger-text;
		border-color: $danger-text;
	}
}
