@import "src/core/scss/variables";

.form-control {
	position: relative;
	width: 100%;
	margin: 15px 0;

	&.form-control-error {

		.label {
			color: $danger-text;
		}

		.input-control {
			border: 1px solid $danger-border;
			color: $danger-text;
		}
	}

	&.form-control-disabled {

		.input-control {

			&:disabled {
				background: $gray-background;
				border-color: $lines-grey-light;
				color: $dark-text;
			}
		}

		.btn-after {
			color: $dark-text;
		}
	}

	&.form-control-md {

		.input-control {
			height: 48px;
			padding: 12px 16px;
			line-height: 1;
			font-size: $text-lg;

			&::placeholder {
				color: $dark-text;
				font-weight: 400;
			}
		}

		.btn-after {
			display: flex;
			top: 14px !important;
		}
	}

	&.form-control-center {

		.input-control {
			text-align: center;

			&::placeholder {
				text-align: center;
			}
		}
	}

	&.form-control-sm {

		.input-control {
			height: 41px;
			padding: 12px 9px;
			line-height: 1;
			font-size: $text-md;

			&::placeholder {
				color: $dark-text;
				font-weight: 400;
			}
		}

		.btn-after {
			top: 6px !important;
		}
	}

	&.form-control-btn-before {

		.input-control {
			padding-left: 48px;
		}

		.btn-before {
			background: transparent;
			padding: 0;
			outline: none;
			border: none;
			font-size: $display-xs;
			line-height: 1;
			position: absolute;
			left: 14px;
			top: 12px;
		}
	}

	&.form-control-btn-after {

		.input-control {
			padding-right: 48px;
		}

		.btn-after {
			background: transparent;
			padding: 0;
			outline: none;
			border: none;
			font-size: $display-xs;
			line-height: 1;
			position: absolute;
			right: 16px;
			top: 12px;
		}
	}

	//&.form-control-date {
	//
	//	.input-control {
	//		padding-right: 48px;
	//	}
	//
	//	.btn-calendar {
	//		background: transparent;
	//		padding: 0;
	//		outline: none;
	//		border: none;
	//		font-size: $display-xs;
	//		line-height: 1;
	//		position: absolute;
	//		right: 14px;
	//		top: 14px;
	//	}
	//}

	.label {
		font-size: $text-md;
		color: $text-grey-for-white;
		font-weight: 400;
		position: absolute;
		left: 20px;
		top: 17px;
		pointer-events: none;
		transform-origin: left center;
		transition: transform, top 250ms;
		display: inline-block;
	}

	.input-control {
		height: 55px;
		width: 100%;
		max-width: 100%;
		border: 1px solid $light-border;
		border-radius: 8px;
		background-color: $white-background;
		font-weight: 500;
		padding: 20px 45px 0 20px;
		line-height: 55px;
		color: $black-text;

		&-static {
			padding: 0 45px 0 112px;
			text-align: right;
		}

		&.input-control-gray {
			background-color: $gray-background;
		}
	}

	.input-control:focus + .label,
	.input-control:not(:placeholder-shown) + .label {
		top: 9px;
		font-size: $text-xs;
	}

	.input-control-static:focus + .label,
	.input-control-static:not(:placeholder-shown) + .label {
		top: 50%;
		transform: translateY(-50%);
		font-size: $text-md;
		font-weight: 500;
	}

	.show-password {
		position: absolute;
		right: 15px;
		top: 13px;
		border: none;
		outline: none;
		background: transparent;
		color: #DFE1E8;
		font-size: 20px;
	}

}

.validation-text {
	margin-left: 8px;
	font-size: $text-xs;
	margin-top: 2px;
	color: $danger-text;

	p {
		margin-bottom: 2px;
	}
}

.text-errors {
	display: flex;
	flex-direction: column;
	gap: 2px;

	&-item {
		font-size: $text-sm;
		line-height: 16.8px;
		color: $danger-text;

		&:first-child {
			margin-top: 8px;
		}
	}
}

.disable-input-arrow {

	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		appearance: none;
		margin: 0;
	}

	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		appearance: none;
		margin: 0;
	}

	&[type="number"] {
		-moz-appearance: textfield;
	}
}

