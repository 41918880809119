@import "../variables";

.core-sidebar {
	position: fixed;
	top: 0;
	right: -100%;
	z-index: $z-index-4;
	height: 100%;
	transition: ease-in-out .7s;

	&.open {
		right: 0;
	}
}

.sidebar {
	background: $white-background;
	border-left: 1px solid $light-border;
	width: 100%;
	height: 100vh;
	padding: 24px;

	&-sm {
		width: 384px;
		padding: 50px 24px 24px 24px;
		overflow-y: auto;
	}

	&-md {
		width: 460px;
		padding: 50px 24px 24px 24px;
		overflow-y: auto;
	}

	&-lg {
		width: 500px;
		padding: 50px 24px 24px 24px;
		overflow-y: auto;
	}

	&-xl {
		width: 554px;
		padding: 50px 24px 24px 24px;
		overflow-y: auto;
	}

	&__wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__header {
		display: flex;
		justify-content: center;
		align-items: center;

		&-title {
			margin: 0;
			font-size: $text-xl;
			font-weight: 500;
			line-height: 1;
		}

		&-close {
			position: absolute;
			top: 15px;
			right: 15px;
			cursor: pointer;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	&__analytics {
		background: $light-background;
		border: 1px solid $light-border;
		border-radius: 8px;
		padding: 12px 0;
		display: flex;
		justify-content: space-evenly;

		&__column {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 12px;
			width: 100%;
			border-left: 1px solid $dark-border;

			&:first-child {
				border-left: 1px solid transparent !important;
			}
		}

		&__cell {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 8px;

			&-title {
				font-size: $text-xs;
				color: $dark-text;
				text-align: center;
				line-height: 1;
			}

			&-value {
				font-weight: 600;
				text-align: center;
				line-height: 1;
			}
		}
	}

	&__orders {

		&-title {
			font-size: $text-lg;
		}

		&__table {
			margin-top: 6px;
			max-height: 350px;
			overflow-y: auto;
			padding-right: 10px;
		}
	}

	&.sidebar-slTp {

		.sidebar {

			&__settings {
				display: flex;
				flex-direction: column;
				gap: 20px;

				&__amount {

					&__range {

						.ngx-slider {

							.ngx-slider-pointer {
								background: $primary-border;
								transform: scale(.6) !important;

								&:focus {
									outline: none;
								}

								&:after {
									background: $white-background !important;
									width: 10px;
									height: 10px;
									top: 11px;
									left: 11px;
									border-radius: 100%;
								}
							}

							.ngx-slider-selection {
								background: $primary-border;
							}

							.ngx-slider-tick {

								&.ngx-slider-selected {
									background: $primary-border;
								}
							}
						}
					}

					&__inputs {
						display: flex;
						justify-content: space-between;
						gap: 46px;

						.form-control {
							margin: 0;
						}

						&-mark {
							font-size: $text-md;
							font-weight: 500;
						}
					}
				}

				&__slTp {
					display: flex;
					align-items: stretch;
					justify-content: space-between;
					gap: 8px;

					&__column {
						display: flex;
						flex-direction: column;
						justify-content: center;

						&__title {
							display: flex;
							align-items: center;
							justify-content: space-between;
							gap: 4px;

							&-value {
								font-weight: 500;
								line-height: 20.8px;
							}
						}

						&__inputs {

							&-item {
								border-radius: 4px;
								font-weight: 500;
								margin-top: 12px;
								border: 1px solid $light-border;
								text-align: center;
								padding: 0 9px;
								height: 40px;
								width: 100%;
							}
						}

						&__types {
							display: flex;
							flex-direction: column;
							align-items: center;
							gap: 12px;
							margin-top: 32px;

							&-title {
								font-size: $text-xs;
								line-height: 12px;
								color: $dark-text;;
								height: 40px;
								display: flex;
								justify-content: center;
								align-items: center;
							}
						}
					}
				}
			}

			&__btns {
				display: flex;
				gap: 16px;
				margin-top: 40px;
				padding-bottom: 24px;

				.btn {
					width: 50%;
				}
			}
		}
	}

	&.sidebar-closePosition {

		.sidebar {

			&__settings {
				display: flex;
				flex-direction: column;
				gap: 20px;

				&__amount {

					&__range {

						.ngx-slider {

							.ngx-slider-pointer {
								background: $primary-border;
								transform: scale(.6) !important;

								&:focus {
									outline: none;
								}

								&:after {
									background: $white-background !important;
									width: 10px;
									height: 10px;
									top: 11px;
									left: 11px;
									border-radius: 100%;
								}
							}

							.ngx-slider-selection {
								background: $primary-border;
							}

							.ngx-slider-tick {

								&.ngx-slider-selected {
									background: $primary-border;
								}
							}
						}
					}

					&__inputs {
						display: flex;
						justify-content: space-between;
						gap: 46px;

						.form-control {
							margin: 0;
						}

						&-mark {
							font-size: $text-md;
							font-weight: 500;
						}
					}
				}

				&__order-info {
					display: flex;
					flex-direction: column;
					gap: 12px;

					.form-control {
						margin: 0;
					}

					&__inner {
						display: flex;
						gap: 12px;
					}

					&-item {
						width: 50%;
					}
				}

				&__switch {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}

			&__btns {
				display: flex;
				gap: 16px;
				margin-top: 40px;
				padding-bottom: 24px;

				.btn {
					width: 50%;
				}
			}
		}
	}

	&.sidebar-orderDetails {

		.sidebar {

			&__type-operation {
				margin-top: 40px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-radius: 4px;
				background: $success-background;
				padding: 11px 15px;

				&-title {
					font-weight: 600;
					color: $white-text;
					line-height: 16px;
				}

				&-date {
					font-weight: 600;
					font-size: $text-sm;
					color: $white-text;
					line-height: 14px;
				}
			}

			&__type-operation-sell {
				background: $danger-background;
			}

			&__ticker {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 10px;
				margin-top: 30px;

				&__preview {
					display: flex;
					align-items: center;
					gap: 8px;

					&-image {
						max-width: 40px;
					}

					&__stub {
						background: $gray-background;
						width: 40px;
						height: 40px;
						border-radius: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						&-title {
							font-weight: 600;
						}
					}

					&__description {
						display: flex;
						flex-direction: column;
						gap: 4px;

						&-title {
							line-height: 24px;
							font-size: $display-xs;
							font-weight: 700;
						}

						&-name {
							line-height: 14px;
							font-size: $text-sm;
							font-weight: 600;
							color: $dark-text;
						}
					}
				}

				&-status {
					font-weight: 600;
					line-height: 19.2px;
				}
			}

			&__info-wrapper {
				margin-top: 30px;
			}

			&__info {
				border-top: 1px solid $light-border;
				padding: 26px 0;
				display: flex;
				flex-direction: column;
				gap: 12px;

				&__row {
					display: flex;
					justify-content: space-between;
					align-items: center;

					&-column {
						display: flex;
						flex-direction: column;
					}

					&-title {
						line-height: 16px;
					}

					&-sub-title {
						color: $dark-text;
						font-size: $text-sm;
					}

					&-value {
						font-weight: 600;
						line-height: 16px;
					}
				}
			}

			&__dates {
				border-top: 1px solid $light-border;
				padding: 26px 0;
				display: flex;
				flex-direction: column;
				gap: 8px;

				&__row {
					display: flex;
					justify-content: space-between;
					align-items: center;

					&-title {
						line-height: 14px;
						font-size: $text-sm;
						color: $dark-text;
					}

					&-value {
						line-height: 14px;
						font-size: $text-sm;
						color: $dark-text;
					}
				}
			}

			&__btns {
				margin-top: 40px;
				display: flex;
				align-items: center;
				gap: 10px;

				.btn {
					width: 100%;
				}
			}
		}
	}

	&.sidebar-changeOrder {

		.sidebar {

			&__btns {
				display: flex;
				gap: 10px;
				margin-top: 40px;

				.btn {
					width: 100%;
				}
			}

			&__btns-confirm {
				display: flex;
				flex-direction: column;
				gap: 20px;
				margin-top: 40px;

				.btn {
					width: 100%;
				}
			}

			&__info {
				margin-top: 40px;
				border-radius: 8px;
				padding: 16px 20px;
				background: $light-background;
				display: flex;
				flex-direction: column;
				gap: 12px;

				&__item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 15px;

					&-title {
						line-height: 20.8px;
						color: $dark-grey;
					}

					&-value {
						line-height: 20.8px;
						font-weight: 600;
					}
				}
			}

			&__inputs {
				margin-top: 40px;
				display: flex;
				flex-direction: column;
				gap: 20px;

				&__item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 40px;

					&-title {
						font-weight: 500;
						line-height: 16px;
					}

					&-line {
						width: 100%;
						height: 1px;
						background: $light-border;
					}
				}
			}
		}
	}

	&.sidebar-positionInfo {

		.sidebar {

			&__btns {
				display: flex;
				gap: 10px;
				margin-top: 40px;

				.btn {
					width: 50%;
				}
			}

			&__ticker {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&__info {
					display: flex;
					align-items: center;
					gap: 8px;

					&-image {
						max-width: 40px;
					}

					&__stub {
						background: $gray-background;
						width: 40px;
						height: 40px;
						border-radius: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						&-title {
							font-weight: 600;
						}
					}

					&__description {
						display: flex;
						flex-direction: column;
						gap: 4px;

						&-title {
							line-height: 24px;
							font-size: $display-xs;
							font-weight: 700;
						}

						&-name {
							line-height: 14px;
							font-size: $text-sm;
							font-weight: 600;
							color: $dark-text;
						}
					}
				}

				&__prices {
					display: flex;
					flex-direction: column;
					gap: 10px;
					align-items: flex-end;

					&-main {
						font-weight: 600;
						font-size: $display-xs;
						line-height: 24px;
					}

					&__change {
						display: flex;
						gap: 4px;
						align-items: center;
						font-weight: 600;
						line-height: 16px;
					}
				}
			}

			.skeleton-asset-info {
				display: flex;
				gap: 8px;
				align-items: center;
				width: 100%;

				&__titles {
					width: 100%;
					display: flex;
					flex-direction: column;
				}
			}

			&__deals {

				&-title {
					font-size: $text-lg;
					margin-bottom: 16px;
				}

				&__table {
					max-height: 350px;
					overflow-y: auto;
					padding-right: 10px;
				}
			}
		}
	}

	&.sidebar-complexOrderDetails {

		.sidebar {

			&__btns {
				margin-top: 40px;
				display: flex;
				align-items: center;
				gap: 10px;

				.btn {
					width: 100%;
				}
			}

			&__info {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 10px;
				margin-top: 40px;

				&__ticker {
					display: flex;
					align-items: center;
					gap: 8px;

					&-icon {
						max-width: 40px;
					}

					&__stub {
						background: $gray-background;
						width: 40px;
						height: 40px;
						border-radius: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						&-title {
							font-weight: 600;
						}
					}

					&__description {
						display: flex;
						flex-direction: column;
						gap: 4px;

						&-title {
							line-height: 24px;
							font-size: $display-xs;
							font-weight: 700;
						}

						&-name {
							line-height: 14px;
							font-size: $text-sm;
							font-weight: 600;
							color: $dark-text;
						}
					}
				}

				&-date {
					line-height: 14px;
					font-size: $text-sm;
					font-weight: 600;
					color: $dark-text;
				}
			}

			&__cards {
				margin-top: 30px;
				display: flex;
				flex-direction: column;
				gap: 16px;

				.complex-order-card__wrapper {
					display: flex;
					flex-direction: column;
					gap: 16px;

					&-indent {
						padding-left: 33px;
					}

					&-help-text {
						line-height: 14px;
						font-size: $text-sm;
					}
				}

				.complex-order-card__wrapper-main {
					background: $white-background;

					.complex-order-card {
						z-index: $z-index-1;
					}
				}

				.complex-order-card__wrapper-main-noHelpText {
					padding-top: 20px;
					margin-top: -20px;
				}

				.complex-order-card {
					border: 1px solid $gray-border;
					border-radius: 8px;
					cursor: pointer;

					&__header {
						display: flex;
						justify-content: space-between;
						align-items: center;
						gap: 5px;
						padding: 11px 15px;
						border-radius: 8px 8px 0 0;
						background: $gray-background;
						position: relative;

						.complex-order-big-arrow {
							position: absolute;
							left: -27px;
							top: -50%;
							transform: translateY(-50%);
							width: 20px;
							z-index: 0;
						}

						.complex-order-arrow-oco-top {
							width: 20px;
							position: absolute;
							left: -27px;
							top: 16px;
						}

						.complex-order-arrow-oco-bottom {
							width: 20px;
							position: absolute;
							left: -27px;
							bottom: 12px;
						}

						&-title {
							font-weight: 600;
							line-height: 16px;
							color: $dark-text;
						}

						&-side {
							font-size: $text-sm;
							font-weight: 600;
							line-height: 14px;
							color: $dark-text;
						}

						&-green {
							background: $success-background;

							.complex-order-card__header-title,
							.complex-order-card__header-side {
								color: $white-text;
							}
						}

						&-red {
							background: $danger-background;

							.complex-order-card__header-title,
							.complex-order-card__header-side {
								color: $white-text;
							}
						}
					}

					&__body {
						border-radius: 0 0 8px 8px;
						padding: 10px 15px;
						display: flex;
						flex-direction: column;
						gap: 12px;
						background: $white-background;

						&__row {
							display: flex;
							justify-content: space-between;
							align-items: center;
							gap: 15px;

							&-title {
								line-height: 16px;
							}

							&-value {
								font-weight: 600;
								line-height: 16px;

								&-green {
									color: $success-text;
								}

								&-red {
									color: $danger-text;
								}
							}
						}
					}
				}
			}
		}
	}

	&.sidebar-changeComplexOrder {

		.sidebar {

			&__btns {
					display: flex;
					gap: 10px;
					margin-top: 40px;

					.btn {
						width: 100%;
					}
				}

			&__info {
					margin-top: 40px;
					border-radius: 8px;
					padding: 16px 20px;
					background: $light-background;
					display: flex;
					flex-direction: column;
					gap: 12px;

					&__item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						gap: 15px;

						&-title {
							line-height: 20.8px;
							color: $dark-grey;
						}

						&-value {
							line-height: 20.8px;
							font-weight: 600;
						}
					}
				}

			&__inputs {
					margin-top: 40px;
					display: flex;
					flex-direction: column;
					gap: 20px;

					&__item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						gap: 40px;

						&-title {
							font-weight: 500;
							line-height: 16px;
						}

						&-line {
							width: 100%;
							height: 1px;
							background: $light-border;
						}
					}
				}

			&__slTp {
					display: flex;
					align-items: stretch;
					justify-content: space-between;
					gap: 8px;
					margin-top: 40px;

					&__column {
						display: flex;
						flex-direction: column;
						justify-content: center;

						&__title {
							display: flex;
							align-items: center;
							gap: 4px;

							&-value {
								font-weight: 500;
								line-height: 20.8px;
							}
						}

						&__counters {
							margin-top: 12px;
							display: flex;
							flex-direction: column;
							gap: 16px;
						}

						&__types {
							display: flex;
							flex-direction: column;
							align-items: center;
							margin-top: 32px;
							gap: 16px;

							&-title {
								font-size: $text-xs;
								line-height: 12px;
								font-weight: 600;
								color: $dark-text;
								display: flex;
								justify-content: center;
								align-items: center;
								height: 20px;
							}
						}
					}
				}
		}
	}

	&.sidebar-confirmOrder {

		.sidebar {

			&__btns {
				display: flex;
				flex-direction: column;
				gap: 20px;
				margin-top: 40px;

				.btn {
					width: 100%;
				}
			}

			&__info {
				margin-top: 40px;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				gap: 12px;

				&__item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 15px;

					&-title {
						line-height: 20.8px;
						color: $dark-grey;
					}

					&-value {
						line-height: 20.8px;
						font-weight: 600;
					}
				}
			}
		}
	}

	&.sidebar-confirmCreateOrder {

		.sidebar {

			&__btns {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-top: 40px;

				.btn {
					width: 100%;
				}
			}

			&__info {
				margin-top: 40px;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				gap: 16px;

				&__item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 15px;

					&-title {
						line-height: 1;
						color: $dark-text;
					}

					&-value {
						line-height: 1;
						font-weight: 500;
						color: $black-text;
					}
				}

				&__line {
					height: 1px;
					background: $light-border;
					width: 100%;
				}
			}
		}
	}

	&.sidebar-cancelOrder {

		.sidebar {

			&__btns {
				display: flex;
				flex-direction: column;
				gap: 20px;
				margin-top: 40px;

				.btn {
					width: 100%;
				}
			}

			&__content {
				margin-top: 40px;
				gap: 0;

				&-text {
					font-size: $text-xl;
					line-height: 130%;
					font-weight: 500;
				}

				&__order {
					border: 1px solid $gray-border;
					border-radius: 8px;
					margin-top: 40px;

					&__header {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 12px;
						border-bottom: 1px solid $gray-border;

						&-symbol {
							font-weight: 600;
							line-height: 16px;
						}

						&-side {
							font-weight: 600;
							line-height: 14px;

							&-green {
								color: $success-text;
							}

							&-red {
								color: $danger-text;
							}
						}
					}

					&__data {
						display: flex;
						flex-direction: column;
						gap: 12px;
						padding: 12px;

						&__row {
							display: flex;
							justify-content: space-between;
							align-items: center;
							gap: 15px;

							&-title {
								line-height: 16px;
							}

							&-value {
								font-weight: 600;
								line-height: 16px;
							}
						}
					}
				}
			}
		}
	}

	&.sidebar-confirmCreateOrderOption {

		.sidebar {

			&__btns {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-top: 40px;

				.btn {
					width: 100%;
				}
			}

			&__info {
				margin-top: 40px;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				gap: 16px;

				&__item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 15px;

					&-title {
						line-height: 1;
						color: $dark-text;
					}

					&-value {
						line-height: 1;
						font-weight: 500;
						color: $black-text;
					}
				}

				&__line {
					height: 1px;
					background: $light-border;
					width: 100%;
				}

				&__description {
					border-radius: 8px;
					border: 1px solid $primary-border;
					padding: 10px 12px;
					margin-top: 15px;
					line-height: 16px;

					&-value {
						font-size: $text-sm;
						font-weight: 500;
					}
				}
			}
		}
	}

	&.sidebar-closePositionOption {

		.sidebar {

			&__btns {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-top: 40px;

				.btn {
					width: 100%;
				}
			}

			&__info {
				margin-top: 40px;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				gap: 16px;

				&__header {
					border: 1px solid $light-border;
					border-radius: 8px;
					padding: 15px;
					display: flex;
					flex-direction: column;
					align-items: center;

					&-title {
						font-weight: 500;
						display: flex;
						justify-content: space-between;
						gap: 8px;
						width: 100%;
					}

					&__content {
						display: flex;
						justify-content: space-between;
						align-items: center;
						gap: 8px;
						width: 100%;

						&-text {
							color: $dark-text;
							font-size: $text-sm;
						}
					}
				}

				&__body {
					display: flex;
					flex-direction: column;
					gap: 12px;

					.form-control {
						margin: 0;
					}

					.qty-mark {
						font-size: $text-md;
						font-weight: 500;
					}
				}
			}
		}
	}

	&.sidebar-executePositionOption {

		.sidebar {

			&__btns {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-top: 40px;

				.btn {
					width: 100%;
				}
			}

			&__info {
				margin-top: 40px;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				gap: 16px;

				&__header {
					border: 1px solid $light-border;
					border-radius: 8px;
					padding: 15px;
					display: flex;
					flex-direction: column;
					align-items: center;

					&-title {
						font-weight: 500;
						display: flex;
						justify-content: space-between;
						gap: 8px;
						width: 100%;
					}

					&__content {
						display: flex;
						justify-content: space-between;
						align-items: center;
						gap: 8px;
						width: 100%;

						&-text {
							color: $dark-text;
							font-size: $text-sm;
						}
					}
				}

				&__body {
					display: flex;
					flex-direction: column;
					gap: 12px;

					.qty-mark {
						font-size: $text-md;
						font-weight: 500;
					}
				}

				&__item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 20px;

					&-title {
						font-size: $text-md;
						font-weight: 500;
						color: $text-black;
					}

					&-value {
						font-size: $text-lg;
						font-weight: 500;
						color: $black-primary;
					}

					.form-control {
						margin: 0;
					}

					&__result {
						width: 100%;
						padding: 10px 12px;
						border-radius: 8px;
						background: $gray-background;
						display: flex;
						flex-direction: column;
						gap: 7px;

						&-row {
							display: flex;
							justify-content: space-between;
							align-items: center;

							&:first-child {

								.sidebar__info__item__result-row-title {
									font-size: $text-md;
									font-weight: 600;
									color: $black-text;
								}

								.sidebar__info__item__result-row-value {
									font-size: $text-md;
									font-weight: 600;
									color: $black-text;
								}
							}

							&-title {
								font-size: $text-sm;
								font-weight: 500;
								line-height: 1;
								color: $dark-text;
							}

							&-value {
								font-size: $text-sm;
								font-weight: 500;
								line-height: 1;
								color: $dark-text;
							}
						}
					}
				}
			}
		}
	}

	&.sidebar-confirmExecutePositionOption {

		.sidebar {

			&__btns {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-top: 40px;

				.btn {
					width: 100%;
				}
			}

			&__info {
				margin-top: 40px;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				gap: 16px;

				&__body {
					display: flex;
					flex-direction: column;
					gap: 12px;
				}

				&__item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 20px;

					&-title {
						line-height: 1;
						color: $dark-text;
					}

					&-value {
						line-height: 1;
						font-weight: 500;
						color: $black-text;
					}

					&__description {
						border-radius: 8px;
						border: 1px solid $primary-border;
						padding: 10px 12px;
						margin-top: 15px;
						line-height: 16px;

						&-value {
							font-size: $text-sm;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}
