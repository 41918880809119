@import "src/core/scss/variables";

.tabs {
	z-index: 0;

	.mat-mdc-tab {
		border-bottom: 1px solid $light-border;

		.mdc-tab-indicator__content--underline {
			transition: none;
			border-color: $black-primary;
		}

		.mdc-tab__text-label {
			transition: none;
			color: $secondary-text;
		}

		&:hover {

			.mdc-tab-indicator__content--underline {
				border-color: $black-primary !important;
			}

			.mdc-tab__text-label {
				color: $black-text !important;
			}
		}

		&:focus {

			.mdc-tab-indicator__content--underline {
				border-color: $black-primary !important;
			}

			.mdc-tab__text-label {
				color: $black-text !important;
			}
		}

		&.mdc-tab--active {

			.mdc-tab__text-label {
				color: $black-text; font-weight: 600
			}
		}
	}
}
