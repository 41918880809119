@import "src/core/scss/variables";

.mat-datepicker-content {
	border-radius: 12px !important;

	.mat-calendar {
		padding: 10px !important;
	}
}

.mat-calendar-header {
	padding: 0 !important;
}

.mat-calendar-controls {
	margin: 0 !important;
}

.mat-calendar-content {
	padding: 0 !important;
}


.mat-calendar-body-cell-content {

	&:hover {
		background: $light-grey !important;
	}
}

.mat-calendar-body-selected {
	background: $primary-text !important;

	&:hover {
		background: $warning-text !important;
	}
}

.cdk-keyboard-focused {
	background: $light-grey !important;

	&:hover {
		background: $light-grey !important;
	}
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
	border-color: $dark-border !important;

	&:hover {
		border: none !important;
	}
}

.mat-calendar-body-cell {

	&:hover {

		.mat-calendar-body-cell-content mat-focus-indicator {
			background: $light-grey !important;
		}
	}
}

.mat-calendar-body-cell
:not(.mat-calendar-body-disabled)
:hover>.mat-calendar-body-cell-content
:not(.mat-calendar-body-selected)
:not(.mat-calendar-body-comparison-identical) {
	background: $light-grey !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
	background: $light-grey !important;
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
	background: none !important;
}
