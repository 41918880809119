@import "../variables";

.switch-theme {
	border-radius: 100px;
	padding: 4px;
	border: 1px solid $light-border;
	cursor: pointer;
	background: $light-background;
	height: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: ease-in-out .2s;

	&.light {
		background: $primary-background;

		.moon {
			transform: translateX(100%);
			opacity: 0;
		}

		.sun {
			opacity: 1;
		}
	}

	&.dark {

		.sun {
			transform: translateX(-100%);
			opacity: 0;
		}

		.moon {
			opacity: 1;
		}
	}

	.switch-icon {
		font-size: $text-lg;
		border-radius: 100%;
		line-height: 1;
		padding: 4px;
		transition: ease-in-out .2s;

		&.moon {
			color: #212129;
		}

		&.sun {
			color: $white-background;
		}
	}
}
