@import "src/core/scss/variables";

.radio {
	appearance: none;
	-webkit-appearance: none;
	background-color: $white-background;
	border: 2px solid $black-text;
	border-radius: 100%;
	width: 20px;
	height: 20px;
	cursor: pointer;
	vertical-align: middle;
	position: relative;

	&:checked::after {
		content: '';
		display: block;
		background-color: $black-text;
		border-radius: 50%;
		width: 8px;
		height: 8px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
