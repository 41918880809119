@import "variables";

input, textarea {
	outline: none;
}

html, body {
	height: 100%;
}

body {
	font-family: $userFont;
	font-size: $text-md;
	color: $black-text;
	min-width: 320px;
	position: relative;
	background: #fff;
	overflow-x: hidden;
}

a {
	text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Gordita', sans-serif;
	line-height: $line-height-title !important;
}

header, footer {
	position: relative;
}

::-webkit-scrollbar {
	width: 6px;
	scrollbar-width: thin;
	scrollbar-color: $primary-background $light-background;
}

::-webkit-scrollbar-thumb {
	background-color: $primary-background;
	border-radius: 8px;
	width: 6px;
}

::-webkit-scrollbar-track {
	background-color: $light-background;
}

