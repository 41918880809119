@import "src/core/scss/variables";

.btn {
	text-decoration: none;
	padding: 0 32px;
	height: 55px;
	border-radius: 100px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-size: $text-lg;
	outline: none;

	&:disabled {
		cursor: not-allowed;
	}
}

.btn-primary {
	background: $primary-background;
	color: $black-text;
	border: none;
	cursor: pointer;
	transition: background .4s ease-out;

	&:hover {
		background: darken($primary-background, 10%);
	}

	&:active {
		background: lighten($primary-background, 10%);
	}

	&:disabled {
		background: lighten($grayTextColor, 20%);
		color: $grayTextColor;
	}
}

.btn-success {
	background: $success-background;
	color: $white-text;
	border: none;
	cursor: pointer;
	transition: background .4s ease-out;

	&:hover {
		background: darken($success-background, 4%);
	}


	&:active {
		background: lighten($success-background, 4%);
	}

	&:disabled {
		background: lighten($grayTextColor, 20%);
		color: $grayTextColor;
	}
}

.btn-danger {
	background: $danger-background;
	color: $white-text;
	border: none;
	cursor: pointer;
	transition: background .4s ease-out;

	&:hover {
		background: darken($danger-background, 4%);
	}


	&:active {
		background: lighten($danger-background, 4%);
	}

	&:disabled {
		background: lighten($grayTextColor, 20%);
		color: $grayTextColor;
	}
}

.btn-dark {
	background: $black-primary;
	color: $yellow-on-black;
	border: none;
	cursor: pointer;
	transition: background .4s ease-out;

	&:hover {
		background: darken($black-primary, 4%);
	}


	&:active {
		background: lighten($black-primary, 4%);
	}

	&:disabled {
		background: lighten($grayTextColor, 20%);
		color: $grayTextColor;
	}
}

.btn-solid-mix {
	background: transparent;
	border: 1px solid $primary-border;
	color: $black-text;
	transition: all .4s ease-in;
}

.btn-solid-mix-danger {
	background: transparent;
	border: 1px solid #DFE1E8;
	color: $danger-text;
	transition: all .4s ease-in;
}

.btn-solid-gray {
	background: transparent;
	border: 1px solid #DFE1E8;
	color: $black-text;
	transition: all .4s ease-in;
}

.btn-solid-danger {
	background: transparent;
	border: 1px solid $danger-border;
	color: $danger-text;
	transition: all .4s ease-in;
}

.btn-lg {
	height: 50px;
	font-size: $text-lg;
}

.btn-md {
	height: 43px;
	font-size: $text-md;
}

.btn-sm {
	height: 32px;
	font-size: $text-sm;
}

.btn-direct {
	border-radius: 5px;
}
