@import "src/core/scss/variables";

.code-input {
  --item-shadow-focused: none !important;

  display: flex !important;
  gap: 12px !important;

  span {
    padding: 0 !important;
  }

  input {
    width: 48px !important;
    border: 1px solid $gray-border !important;
    height: 64px !important;
    border-radius: 8px !important;
    background: $white-background !important;
    font-weight: 400 !important;
    color: $black-text !important;
    font-size: $display-md !important;
  }
}
