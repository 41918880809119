@import 'animate.css';
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #ff4081;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3f51b5;
  --mdc-linear-progress-track-color: rgba(63, 81, 181, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(63, 81, 181, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(63, 81, 181, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(63, 81, 181, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ff4081;
  --mdc-linear-progress-track-color: rgba(255, 64, 129, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 64, 129, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 64, 129, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 64, 129, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-caret-color: #3f51b5;
  --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
  --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3f51b5;
  --mdc-outlined-text-field-focus-outline-color: #3f51b5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(63, 81, 181, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(255, 64, 129, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #ff4081;
  --mdc-filled-text-field-focus-active-indicator-color: #ff4081;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
  --mdc-outlined-text-field-caret-color: #ff4081;
  --mdc-outlined-text-field-focus-outline-color: #ff4081;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 64, 129, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3f51b5;
  --mdc-chip-elevated-disabled-container-color: #3f51b5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff4081;
  --mdc-chip-elevated-disabled-container-color: #ff4081;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #3949ab;
  --mdc-switch-selected-handle-color: #3949ab;
  --mdc-switch-selected-hover-state-layer-color: #3949ab;
  --mdc-switch-selected-pressed-state-layer-color: #3949ab;
  --mdc-switch-selected-focus-handle-color: #1a237e;
  --mdc-switch-selected-hover-handle-color: #1a237e;
  --mdc-switch-selected-pressed-handle-color: #1a237e;
  --mdc-switch-selected-focus-track-color: #7986cb;
  --mdc-switch-selected-hover-track-color: #7986cb;
  --mdc-switch-selected-pressed-track-color: #7986cb;
  --mdc-switch-selected-track-color: #7986cb;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #3f51b5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ff4081;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #3f51b5;
  --mdc-slider-focus-handle-color: #3f51b5;
  --mdc-slider-hover-handle-color: #3f51b5;
  --mdc-slider-active-track-color: #3f51b5;
  --mdc-slider-inactive-track-color: #3f51b5;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
  --mat-mdc-slider-ripple-color: #3f51b5;
  --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #ff4081;
  --mdc-slider-focus-handle-color: #ff4081;
  --mdc-slider-hover-handle-color: #ff4081;
  --mdc-slider-active-track-color: #ff4081;
  --mdc-slider-inactive-track-color: #ff4081;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff4081;
  --mat-mdc-slider-ripple-color: #ff4081;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 64, 129, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 64, 129, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #3f51b5;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #3f51b5;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3f51b5;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3f51b5;
  --mat-tab-header-active-ripple-color: #3f51b5;
  --mat-tab-header-inactive-ripple-color: #3f51b5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3f51b5;
  --mat-tab-header-active-hover-label-text-color: #3f51b5;
  --mat-tab-header-active-focus-indicator-color: #3f51b5;
  --mat-tab-header-active-hover-indicator-color: #3f51b5;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ff4081;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff4081;
  --mat-tab-header-active-ripple-color: #ff4081;
  --mat-tab-header-inactive-ripple-color: #ff4081;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff4081;
  --mat-tab-header-active-hover-label-text-color: #ff4081;
  --mat-tab-header-active-focus-indicator-color: #ff4081;
  --mat-tab-header-active-hover-indicator-color: #ff4081;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3f51b5;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ff4081;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ff4081;
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #3f51b5;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #ff4081;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #ff4081;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #3f51b5;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #ff4081;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #3f51b5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #ff4081;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #3f51b5;
}
.mat-icon.mat-accent {
  --mat-icon-color: #ff4081;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3f51b5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3f51b5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3f51b5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff4081;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff4081;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff4081;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3f51b5;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #ff4081;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-elevation-shadow: $default-shadow !important;
  --mdc-dialog-container-shape: 20px !important;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow-y: inherit !important;
}

/*
	100 - Thin
	200 - Extra Light (Ultra Light)
	300 - Light
	400 - Normal
	500 - Medium
	600 - Semi Bold (Demi Bold)
	700 - Bold
	800 - Extra Bold (Ultra Bold)
	900 - Black (Heavy)
*/
/* SF Pro */
@font-face {
  font-family: "sf-pro-ex";
  font-style: normal;
  font-weight: 100;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-Thin.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: italic;
  font-weight: 100;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-ThinItalic.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: normal;
  font-weight: 200;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-Ultralight.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: italic;
  font-weight: 200;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-UltralightItalic.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-Light.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: italic;
  font-weight: 300;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-LightItalic.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: italic;
  font-weight: 400;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-RegularItalic.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: italic;
  font-weight: 500;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-MediumItalic.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-Semibold.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: italic;
  font-weight: 600;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-SemiboldItalic.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: italic;
  font-weight: 700;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-BoldItalic.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-Heavy.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: italic;
  font-weight: 800;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-HeavyItalic.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-Black.woff2") format("woff2");
}
@font-face {
  font-family: "sf-pro-ex";
  font-style: italic;
  font-weight: 900;
  src: url("/assets/fonts/Sf-Pro/SFProDisplay-BlackItalic.woff2") format("woff2");
}
/* Gordita */
@font-face {
  font-family: "Gordita";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/Gordita/Gordita-Light.woff2") format("woff2");
}
@font-face {
  font-family: "Gordita";
  font-style: italic;
  font-weight: 300;
  src: url("/assets/fonts/Gordita/Gordita-LightItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Gordita";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Gordita/Gordita-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Gordita";
  font-style: italic;
  font-weight: 400;
  src: url("/assets/fonts/Gordita/Gordita-Italic.woff2") format("woff2");
}
@font-face {
  font-family: "Gordita";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/Gordita/Gordita-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Gordita";
  font-style: italic;
  font-weight: 500;
  src: url("/assets/fonts/Gordita/Gordita-MediumItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Gordita";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/Gordita/Gordita-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Gordita";
  font-style: italic;
  font-weight: 700;
  src: url("/assets/fonts/Gordita/Gordita-BoldItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Gordita";
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/Gordita/Gordita-Black.woff2") format("woff2");
}
@font-face {
  font-family: "Gordita";
  font-style: italic;
  font-weight: 900;
  src: url("/assets/fonts/Gordita/Gordita-BlackItalic.woff2") format("woff2");
}
/*!
 * Bootstrap Reboot v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

/*!
 * Bootstrap Grid v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@font-face {
  font-family: "boxicons";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/boxicons.eot");
  src: url("../fonts/boxicons.eot") format("embedded-opentype"), url("../fonts/boxicons.woff2") format("woff2"), url("../fonts/boxicons.woff") format("woff"), url("../fonts/boxicons.ttf") format("truetype"), url("../fonts/boxicons.svg?#boxicons") format("svg");
}
.bx {
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  display: inline-block;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bx-ul {
  margin-left: 2em;
  padding-left: 0;
  list-style: none;
}

.bx-ul > li {
  position: relative;
}

.bx-ul .bx {
  font-size: inherit;
  line-height: inherit;
  position: absolute;
  left: -2em;
  width: 2em;
  text-align: center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes burst {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes burst {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes flashing {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
@keyframes flashing {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@keyframes fade-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@-webkit-keyframes fade-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@keyframes fade-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@-webkit-keyframes fade-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@keyframes fade-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@-webkit-keyframes fade-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@keyframes fade-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }
  40%, 60%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.bx-spin {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.bx-spin-hover:hover {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.bx-tada {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
}

.bx-tada-hover:hover {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
}

.bx-flashing {
  -webkit-animation: flashing 1.5s infinite linear;
  animation: flashing 1.5s infinite linear;
}

.bx-flashing-hover:hover {
  -webkit-animation: flashing 1.5s infinite linear;
  animation: flashing 1.5s infinite linear;
}

.bx-burst {
  -webkit-animation: burst 1.5s infinite linear;
  animation: burst 1.5s infinite linear;
}

.bx-burst-hover:hover {
  -webkit-animation: burst 1.5s infinite linear;
  animation: burst 1.5s infinite linear;
}

.bx-fade-up {
  -webkit-animation: fade-up 1.5s infinite linear;
  animation: fade-up 1.5s infinite linear;
}

.bx-fade-up-hover:hover {
  -webkit-animation: fade-up 1.5s infinite linear;
  animation: fade-up 1.5s infinite linear;
}

.bx-fade-down {
  -webkit-animation: fade-down 1.5s infinite linear;
  animation: fade-down 1.5s infinite linear;
}

.bx-fade-down-hover:hover {
  -webkit-animation: fade-down 1.5s infinite linear;
  animation: fade-down 1.5s infinite linear;
}

.bx-fade-left {
  -webkit-animation: fade-left 1.5s infinite linear;
  animation: fade-left 1.5s infinite linear;
}

.bx-fade-left-hover:hover {
  -webkit-animation: fade-left 1.5s infinite linear;
  animation: fade-left 1.5s infinite linear;
}

.bx-fade-right {
  -webkit-animation: fade-right 1.5s infinite linear;
  animation: fade-right 1.5s infinite linear;
}

.bx-fade-right-hover:hover {
  -webkit-animation: fade-right 1.5s infinite linear;
  animation: fade-right 1.5s infinite linear;
}

.bx-xs {
  font-size: 1rem !important;
}

.bx-sm {
  font-size: 1.55rem !important;
}

.bx-md {
  font-size: 2.25rem !important;
}

.bx-lg {
  font-size: 3rem !important;
}

.bx-fw {
  font-size: 1.2857142857em;
  line-height: 0.8em;
  width: 1.2857142857em;
  height: 0.8em;
  margin-top: -0.2em !important;
  vertical-align: middle;
}

.bx-pull-left {
  float: left;
  margin-right: 0.3em !important;
}

.bx-pull-right {
  float: right;
  margin-left: 0.3em !important;
}

.bx-rotate-90 {
  transform: rotate(90deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.bx-rotate-180 {
  transform: rotate(180deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
}

.bx-rotate-270 {
  transform: rotate(270deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.bx-flip-horizontal {
  transform: scaleX(-1);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
}

.bx-flip-vertical {
  transform: scaleY(-1);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.bx-border {
  padding: 0.25em;
  border: 0.07em solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
}

.bx-border-circle {
  padding: 0.25em;
  border: 0.07em solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.bxs-balloon:before {
  content: "\eb60";
}

.bxs-castle:before {
  content: "\eb79";
}

.bxs-coffee-bean:before {
  content: "\eb92";
}

.bxs-objects-horizontal-center:before {
  content: "\ebab";
}

.bxs-objects-horizontal-left:before {
  content: "\ebc4";
}

.bxs-objects-horizontal-right:before {
  content: "\ebdd";
}

.bxs-objects-vertical-bottom:before {
  content: "\ebf6";
}

.bxs-objects-vertical-center:before {
  content: "\ef40";
}

.bxs-objects-vertical-top:before {
  content: "\ef41";
}

.bxs-pear:before {
  content: "\ef42";
}

.bxs-shield-minus:before {
  content: "\ef43";
}

.bxs-shield-plus:before {
  content: "\ef44";
}

.bxs-shower:before {
  content: "\ef45";
}

.bxs-sushi:before {
  content: "\ef46";
}

.bxs-universal-access:before {
  content: "\ef47";
}

.bx-child:before {
  content: "\ef48";
}

.bx-horizontal-left:before {
  content: "\ef49";
}

.bx-horizontal-right:before {
  content: "\ef4a";
}

.bx-objects-horizontal-center:before {
  content: "\ef4b";
}

.bx-objects-horizontal-left:before {
  content: "\ef4c";
}

.bx-objects-horizontal-right:before {
  content: "\ef4d";
}

.bx-objects-vertical-bottom:before {
  content: "\ef4e";
}

.bx-objects-vertical-center:before {
  content: "\ef4f";
}

.bx-objects-vertical-top:before {
  content: "\ef50";
}

.bx-rfid:before {
  content: "\ef51";
}

.bx-shield-minus:before {
  content: "\ef52";
}

.bx-shield-plus:before {
  content: "\ef53";
}

.bx-shower:before {
  content: "\ef54";
}

.bx-sushi:before {
  content: "\ef55";
}

.bx-universal-access:before {
  content: "\ef56";
}

.bx-vertical-bottom:before {
  content: "\ef57";
}

.bx-vertical-top:before {
  content: "\ef58";
}

.bxl-graphql:before {
  content: "\ef59";
}

.bxl-typescript:before {
  content: "\ef5a";
}

.bxs-color:before {
  content: "\ef39";
}

.bx-reflect-horizontal:before {
  content: "\ef3a";
}

.bx-reflect-vertical:before {
  content: "\ef3b";
}

.bx-color:before {
  content: "\ef3c";
}

.bxl-mongodb:before {
  content: "\ef3d";
}

.bxl-postgresql:before {
  content: "\ef3e";
}

.bxl-deezer:before {
  content: "\ef3f";
}

.bxs-hard-hat:before {
  content: "\ef2a";
}

.bxs-home-alt-2:before {
  content: "\ef2b";
}

.bxs-cheese:before {
  content: "\ef2c";
}

.bx-home-alt-2:before {
  content: "\ef2d";
}

.bx-hard-hat:before {
  content: "\ef2e";
}

.bx-cheese:before {
  content: "\ef2f";
}

.bx-cart-add:before {
  content: "\ef30";
}

.bx-cart-download:before {
  content: "\ef31";
}

.bx-no-signal:before {
  content: "\ef32";
}

.bx-signal-1:before {
  content: "\ef33";
}

.bx-signal-2:before {
  content: "\ef34";
}

.bx-signal-3:before {
  content: "\ef35";
}

.bx-signal-4:before {
  content: "\ef36";
}

.bx-signal-5:before {
  content: "\ef37";
}

.bxl-xing:before {
  content: "\ef38";
}

.bxl-meta:before {
  content: "\ef27";
}

.bx-lemon:before {
  content: "\ef28";
}

.bxs-lemon:before {
  content: "\ef29";
}

.bx-cricket-ball:before {
  content: "\ef0c";
}

.bx-baguette:before {
  content: "\ef0d";
}

.bx-bowl-hot:before {
  content: "\ef0e";
}

.bx-bowl-rice:before {
  content: "\ef0f";
}

.bx-cable-car:before {
  content: "\ef10";
}

.bx-candles:before {
  content: "\ef11";
}

.bx-circle-half:before {
  content: "\ef12";
}

.bx-circle-quarter:before {
  content: "\ef13";
}

.bx-circle-three-quarter:before {
  content: "\ef14";
}

.bx-cross:before {
  content: "\ef15";
}

.bx-fork:before {
  content: "\ef16";
}

.bx-knife:before {
  content: "\ef17";
}

.bx-money-withdraw:before {
  content: "\ef18";
}

.bx-popsicle:before {
  content: "\ef19";
}

.bx-scatter-chart:before {
  content: "\ef1a";
}

.bxs-baguette:before {
  content: "\ef1b";
}

.bxs-bowl-hot:before {
  content: "\ef1c";
}

.bxs-bowl-rice:before {
  content: "\ef1d";
}

.bxs-cable-car:before {
  content: "\ef1e";
}

.bxs-circle-half:before {
  content: "\ef1f";
}

.bxs-circle-quarter:before {
  content: "\ef20";
}

.bxs-circle-three-quarter:before {
  content: "\ef21";
}

.bxs-cricket-ball:before {
  content: "\ef22";
}

.bxs-invader:before {
  content: "\ef23";
}

.bx-male-female:before {
  content: "\ef24";
}

.bxs-popsicle:before {
  content: "\ef25";
}

.bxs-tree-alt:before {
  content: "\ef26";
}

.bxl-venmo:before {
  content: "\e900";
}

.bxl-upwork:before {
  content: "\e901";
}

.bxl-netlify:before {
  content: "\e902";
}

.bxl-java:before {
  content: "\e903";
}

.bxl-heroku:before {
  content: "\e904";
}

.bxl-go-lang:before {
  content: "\e905";
}

.bxl-gmail:before {
  content: "\e906";
}

.bxl-flask:before {
  content: "\e907";
}

.bxl-99designs:before {
  content: "\e908";
}

.bxl-500px:before {
  content: "\e909";
}

.bxl-adobe:before {
  content: "\e90a";
}

.bxl-airbnb:before {
  content: "\e90b";
}

.bxl-algolia:before {
  content: "\e90c";
}

.bxl-amazon:before {
  content: "\e90d";
}

.bxl-android:before {
  content: "\e90e";
}

.bxl-angular:before {
  content: "\e90f";
}

.bxl-apple:before {
  content: "\e910";
}

.bxl-audible:before {
  content: "\e911";
}

.bxl-aws:before {
  content: "\e912";
}

.bxl-baidu:before {
  content: "\e913";
}

.bxl-behance:before {
  content: "\e914";
}

.bxl-bing:before {
  content: "\e915";
}

.bxl-bitcoin:before {
  content: "\e916";
}

.bxl-blender:before {
  content: "\e917";
}

.bxl-blogger:before {
  content: "\e918";
}

.bxl-bootstrap:before {
  content: "\e919";
}

.bxl-chrome:before {
  content: "\e91a";
}

.bxl-codepen:before {
  content: "\e91b";
}

.bxl-c-plus-plus:before {
  content: "\e91c";
}

.bxl-creative-commons:before {
  content: "\e91d";
}

.bxl-css3:before {
  content: "\e91e";
}

.bxl-dailymotion:before {
  content: "\e91f";
}

.bxl-deviantart:before {
  content: "\e920";
}

.bxl-dev-to:before {
  content: "\e921";
}

.bxl-digg:before {
  content: "\e922";
}

.bxl-digitalocean:before {
  content: "\e923";
}

.bxl-discord:before {
  content: "\e924";
}

.bxl-discord-alt:before {
  content: "\e925";
}

.bxl-discourse:before {
  content: "\e926";
}

.bxl-django:before {
  content: "\e927";
}

.bxl-docker:before {
  content: "\e928";
}

.bxl-dribbble:before {
  content: "\e929";
}

.bxl-dropbox:before {
  content: "\e92a";
}

.bxl-drupal:before {
  content: "\e92b";
}

.bxl-ebay:before {
  content: "\e92c";
}

.bxl-edge:before {
  content: "\e92d";
}

.bxl-etsy:before {
  content: "\e92e";
}

.bxl-facebook:before {
  content: "\e92f";
}

.bxl-facebook-circle:before {
  content: "\e930";
}

.bxl-facebook-square:before {
  content: "\e931";
}

.bxl-figma:before {
  content: "\e932";
}

.bxl-firebase:before {
  content: "\e933";
}

.bxl-firefox:before {
  content: "\e934";
}

.bxl-flickr:before {
  content: "\e935";
}

.bxl-flickr-square:before {
  content: "\e936";
}

.bxl-flutter:before {
  content: "\e937";
}

.bxl-foursquare:before {
  content: "\e938";
}

.bxl-git:before {
  content: "\e939";
}

.bxl-github:before {
  content: "\e93a";
}

.bxl-gitlab:before {
  content: "\e93b";
}

.bxl-google:before {
  content: "\e93c";
}

.bxl-google-cloud:before {
  content: "\e93d";
}

.bxl-google-plus:before {
  content: "\e93e";
}

.bxl-google-plus-circle:before {
  content: "\e93f";
}

.bxl-html5:before {
  content: "\e940";
}

.bxl-imdb:before {
  content: "\e941";
}

.bxl-instagram:before {
  content: "\e942";
}

.bxl-instagram-alt:before {
  content: "\e943";
}

.bxl-internet-explorer:before {
  content: "\e944";
}

.bxl-invision:before {
  content: "\e945";
}

.bxl-javascript:before {
  content: "\e946";
}

.bxl-joomla:before {
  content: "\e947";
}

.bxl-jquery:before {
  content: "\e948";
}

.bxl-jsfiddle:before {
  content: "\e949";
}

.bxl-kickstarter:before {
  content: "\e94a";
}

.bxl-kubernetes:before {
  content: "\e94b";
}

.bxl-less:before {
  content: "\e94c";
}

.bxl-linkedin:before {
  content: "\e94d";
}

.bxl-linkedin-square:before {
  content: "\e94e";
}

.bxl-magento:before {
  content: "\e94f";
}

.bxl-mailchimp:before {
  content: "\e950";
}

.bxl-markdown:before {
  content: "\e951";
}

.bxl-mastercard:before {
  content: "\e952";
}

.bxl-mastodon:before {
  content: "\e953";
}

.bxl-medium:before {
  content: "\e954";
}

.bxl-medium-old:before {
  content: "\e955";
}

.bxl-medium-square:before {
  content: "\e956";
}

.bxl-messenger:before {
  content: "\e957";
}

.bxl-microsoft:before {
  content: "\e958";
}

.bxl-microsoft-teams:before {
  content: "\e959";
}

.bxl-nodejs:before {
  content: "\e95a";
}

.bxl-ok-ru:before {
  content: "\e95b";
}

.bxl-opera:before {
  content: "\e95c";
}

.bxl-patreon:before {
  content: "\e95d";
}

.bxl-paypal:before {
  content: "\e95e";
}

.bxl-periscope:before {
  content: "\e95f";
}

.bxl-php:before {
  content: "\e960";
}

.bxl-pinterest:before {
  content: "\e961";
}

.bxl-pinterest-alt:before {
  content: "\e962";
}

.bxl-play-store:before {
  content: "\e963";
}

.bxl-pocket:before {
  content: "\e964";
}

.bxl-product-hunt:before {
  content: "\e965";
}

.bxl-python:before {
  content: "\e966";
}

.bxl-quora:before {
  content: "\e967";
}

.bxl-react:before {
  content: "\e968";
}

.bxl-redbubble:before {
  content: "\e969";
}

.bxl-reddit:before {
  content: "\e96a";
}

.bxl-redux:before {
  content: "\e96b";
}

.bxl-sass:before {
  content: "\e96c";
}

.bxl-shopify:before {
  content: "\e96d";
}

.bxl-sketch:before {
  content: "\e96e";
}

.bxl-skype:before {
  content: "\e96f";
}

.bxl-slack:before {
  content: "\e970";
}

.bxl-slack-old:before {
  content: "\e971";
}

.bxl-snapchat:before {
  content: "\e972";
}

.bxl-soundcloud:before {
  content: "\e973";
}

.bxl-spotify:before {
  content: "\e974";
}

.bxl-spring-boot:before {
  content: "\e975";
}

.bxl-squarespace:before {
  content: "\e976";
}

.bxl-stack-overflow:before {
  content: "\e977";
}

.bxl-steam:before {
  content: "\e978";
}

.bxl-stripe:before {
  content: "\e979";
}

.bxl-tailwind-css:before {
  content: "\e97a";
}

.bxl-telegram:before {
  content: "\e97b";
}

.bxl-tiktok:before {
  content: "\e97c";
}

.bxl-trello:before {
  content: "\e97d";
}

.bxl-trip-advisor:before {
  content: "\e97e";
}

.bxl-tumblr:before {
  content: "\e97f";
}

.bxl-tux:before {
  content: "\e980";
}

.bxl-twitch:before {
  content: "\e981";
}

.bxl-twitter:before {
  content: "\e982";
}

.bxl-unity:before {
  content: "\e983";
}

.bxl-unsplash:before {
  content: "\e984";
}

.bxl-vimeo:before {
  content: "\e985";
}

.bxl-visa:before {
  content: "\e986";
}

.bxl-visual-studio:before {
  content: "\e987";
}

.bxl-vk:before {
  content: "\e988";
}

.bxl-vuejs:before {
  content: "\e989";
}

.bxl-whatsapp:before {
  content: "\e98a";
}

.bxl-whatsapp-square:before {
  content: "\e98b";
}

.bxl-wikipedia:before {
  content: "\e98c";
}

.bxl-windows:before {
  content: "\e98d";
}

.bxl-wix:before {
  content: "\e98e";
}

.bxl-wordpress:before {
  content: "\e98f";
}

.bxl-yahoo:before {
  content: "\e990";
}

.bxl-yelp:before {
  content: "\e991";
}

.bxl-youtube:before {
  content: "\e992";
}

.bxl-zoom:before {
  content: "\e993";
}

.bx-collapse-alt:before {
  content: "\e994";
}

.bx-collapse-horizontal:before {
  content: "\e995";
}

.bx-collapse-vertical:before {
  content: "\e996";
}

.bx-expand-horizontal:before {
  content: "\e997";
}

.bx-expand-vertical:before {
  content: "\e998";
}

.bx-injection:before {
  content: "\e999";
}

.bx-leaf:before {
  content: "\e99a";
}

.bx-math:before {
  content: "\e99b";
}

.bx-party:before {
  content: "\e99c";
}

.bx-abacus:before {
  content: "\e99d";
}

.bx-accessibility:before {
  content: "\e99e";
}

.bx-add-to-queue:before {
  content: "\e99f";
}

.bx-adjust:before {
  content: "\e9a0";
}

.bx-alarm:before {
  content: "\e9a1";
}

.bx-alarm-add:before {
  content: "\e9a2";
}

.bx-alarm-exclamation:before {
  content: "\e9a3";
}

.bx-alarm-off:before {
  content: "\e9a4";
}

.bx-alarm-snooze:before {
  content: "\e9a5";
}

.bx-album:before {
  content: "\e9a6";
}

.bx-align-justify:before {
  content: "\e9a7";
}

.bx-align-left:before {
  content: "\e9a8";
}

.bx-align-middle:before {
  content: "\e9a9";
}

.bx-align-right:before {
  content: "\e9aa";
}

.bx-analyse:before {
  content: "\e9ab";
}

.bx-anchor:before {
  content: "\e9ac";
}

.bx-angry:before {
  content: "\e9ad";
}

.bx-aperture:before {
  content: "\e9ae";
}

.bx-arch:before {
  content: "\e9af";
}

.bx-archive:before {
  content: "\e9b0";
}

.bx-archive-in:before {
  content: "\e9b1";
}

.bx-archive-out:before {
  content: "\e9b2";
}

.bx-area:before {
  content: "\e9b3";
}

.bx-arrow-back:before {
  content: "\e9b4";
}

.bx-arrow-from-bottom:before {
  content: "\e9b5";
}

.bx-arrow-from-left:before {
  content: "\e9b6";
}

.bx-arrow-from-right:before {
  content: "\e9b7";
}

.bx-arrow-from-top:before {
  content: "\e9b8";
}

.bx-arrow-to-bottom:before {
  content: "\e9b9";
}

.bx-arrow-to-left:before {
  content: "\e9ba";
}

.bx-arrow-to-right:before {
  content: "\e9bb";
}

.bx-arrow-to-top:before {
  content: "\e9bc";
}

.bx-at:before {
  content: "\e9bd";
}

.bx-atom:before {
  content: "\e9be";
}

.bx-award:before {
  content: "\e9bf";
}

.bx-badge:before {
  content: "\e9c0";
}

.bx-badge-check:before {
  content: "\e9c1";
}

.bx-ball:before {
  content: "\e9c2";
}

.bx-band-aid:before {
  content: "\e9c3";
}

.bx-bar-chart:before {
  content: "\e9c4";
}

.bx-bar-chart-alt:before {
  content: "\e9c5";
}

.bx-bar-chart-alt-2:before {
  content: "\e9c6";
}

.bx-bar-chart-square:before {
  content: "\e9c7";
}

.bx-barcode:before {
  content: "\e9c8";
}

.bx-barcode-reader:before {
  content: "\e9c9";
}

.bx-baseball:before {
  content: "\e9ca";
}

.bx-basket:before {
  content: "\e9cb";
}

.bx-basketball:before {
  content: "\e9cc";
}

.bx-bath:before {
  content: "\e9cd";
}

.bx-battery:before {
  content: "\e9ce";
}

.bx-bed:before {
  content: "\e9cf";
}

.bx-been-here:before {
  content: "\e9d0";
}

.bx-beer:before {
  content: "\e9d1";
}

.bx-bell:before {
  content: "\e9d2";
}

.bx-bell-minus:before {
  content: "\e9d3";
}

.bx-bell-off:before {
  content: "\e9d4";
}

.bx-bell-plus:before {
  content: "\e9d5";
}

.bx-bible:before {
  content: "\e9d6";
}

.bx-bitcoin:before {
  content: "\e9d7";
}

.bx-blanket:before {
  content: "\e9d8";
}

.bx-block:before {
  content: "\e9d9";
}

.bx-bluetooth:before {
  content: "\e9da";
}

.bx-body:before {
  content: "\e9db";
}

.bx-bold:before {
  content: "\e9dc";
}

.bx-bolt-circle:before {
  content: "\e9dd";
}

.bx-bomb:before {
  content: "\e9de";
}

.bx-bone:before {
  content: "\e9df";
}

.bx-bong:before {
  content: "\e9e0";
}

.bx-book:before {
  content: "\e9e1";
}

.bx-book-add:before {
  content: "\e9e2";
}

.bx-book-alt:before {
  content: "\e9e3";
}

.bx-book-bookmark:before {
  content: "\e9e4";
}

.bx-book-content:before {
  content: "\e9e5";
}

.bx-book-heart:before {
  content: "\e9e6";
}

.bx-bookmark:before {
  content: "\e9e7";
}

.bx-bookmark-alt:before {
  content: "\e9e8";
}

.bx-bookmark-alt-minus:before {
  content: "\e9e9";
}

.bx-bookmark-alt-plus:before {
  content: "\e9ea";
}

.bx-bookmark-heart:before {
  content: "\e9eb";
}

.bx-bookmark-minus:before {
  content: "\e9ec";
}

.bx-bookmark-plus:before {
  content: "\e9ed";
}

.bx-bookmarks:before {
  content: "\e9ee";
}

.bx-book-open:before {
  content: "\e9ef";
}

.bx-book-reader:before {
  content: "\e9f0";
}

.bx-border-all:before {
  content: "\e9f1";
}

.bx-border-bottom:before {
  content: "\e9f2";
}

.bx-border-inner:before {
  content: "\e9f3";
}

.bx-border-left:before {
  content: "\e9f4";
}

.bx-border-none:before {
  content: "\e9f5";
}

.bx-border-outer:before {
  content: "\e9f6";
}

.bx-border-radius:before {
  content: "\e9f7";
}

.bx-border-right:before {
  content: "\e9f8";
}

.bx-border-top:before {
  content: "\e9f9";
}

.bx-bot:before {
  content: "\e9fa";
}

.bx-bowling-ball:before {
  content: "\e9fb";
}

.bx-box:before {
  content: "\e9fc";
}

.bx-bracket:before {
  content: "\e9fd";
}

.bx-braille:before {
  content: "\e9fe";
}

.bx-brain:before {
  content: "\e9ff";
}

.bx-briefcase:before {
  content: "\ea00";
}

.bx-briefcase-alt:before {
  content: "\ea01";
}

.bx-briefcase-alt-2:before {
  content: "\ea02";
}

.bx-brightness:before {
  content: "\ea03";
}

.bx-brightness-half:before {
  content: "\ea04";
}

.bx-broadcast:before {
  content: "\ea05";
}

.bx-brush:before {
  content: "\ea06";
}

.bx-brush-alt:before {
  content: "\ea07";
}

.bx-bug:before {
  content: "\ea08";
}

.bx-bug-alt:before {
  content: "\ea09";
}

.bx-building:before {
  content: "\ea0a";
}

.bx-building-house:before {
  content: "\ea0b";
}

.bx-buildings:before {
  content: "\ea0c";
}

.bx-bulb:before {
  content: "\ea0d";
}

.bx-bullseye:before {
  content: "\ea0e";
}

.bx-buoy:before {
  content: "\ea0f";
}

.bx-bus:before {
  content: "\ea10";
}

.bx-bus-school:before {
  content: "\ea11";
}

.bx-cabinet:before {
  content: "\ea12";
}

.bx-cake:before {
  content: "\ea13";
}

.bx-calculator:before {
  content: "\ea14";
}

.bx-calendar:before {
  content: "\ea15";
}

.bx-calendar-alt:before {
  content: "\ea16";
}

.bx-calendar-check:before {
  content: "\ea17";
}

.bx-calendar-edit:before {
  content: "\ea18";
}

.bx-calendar-event:before {
  content: "\ea19";
}

.bx-calendar-exclamation:before {
  content: "\ea1a";
}

.bx-calendar-heart:before {
  content: "\ea1b";
}

.bx-calendar-minus:before {
  content: "\ea1c";
}

.bx-calendar-plus:before {
  content: "\ea1d";
}

.bx-calendar-star:before {
  content: "\ea1e";
}

.bx-calendar-week:before {
  content: "\ea1f";
}

.bx-calendar-x:before {
  content: "\ea20";
}

.bx-camera:before {
  content: "\ea21";
}

.bx-camera-home:before {
  content: "\ea22";
}

.bx-camera-movie:before {
  content: "\ea23";
}

.bx-camera-off:before {
  content: "\ea24";
}

.bx-capsule:before {
  content: "\ea25";
}

.bx-captions:before {
  content: "\ea26";
}

.bx-car:before {
  content: "\ea27";
}

.bx-card:before {
  content: "\ea28";
}

.bx-caret-down:before {
  content: "\ea29";
}

.bx-caret-down-circle:before {
  content: "\ea2a";
}

.bx-caret-down-square:before {
  content: "\ea2b";
}

.bx-caret-left:before {
  content: "\ea2c";
}

.bx-caret-left-circle:before {
  content: "\ea2d";
}

.bx-caret-left-square:before {
  content: "\ea2e";
}

.bx-caret-right:before {
  content: "\ea2f";
}

.bx-caret-right-circle:before {
  content: "\ea30";
}

.bx-caret-right-square:before {
  content: "\ea31";
}

.bx-caret-up:before {
  content: "\ea32";
}

.bx-caret-up-circle:before {
  content: "\ea33";
}

.bx-caret-up-square:before {
  content: "\ea34";
}

.bx-carousel:before {
  content: "\ea35";
}

.bx-cart:before {
  content: "\ea36";
}

.bx-cart-alt:before {
  content: "\ea37";
}

.bx-cast:before {
  content: "\ea38";
}

.bx-category:before {
  content: "\ea39";
}

.bx-category-alt:before {
  content: "\ea3a";
}

.bx-cctv:before {
  content: "\ea3b";
}

.bx-certification:before {
  content: "\ea3c";
}

.bx-chair:before {
  content: "\ea3d";
}

.bx-chalkboard:before {
  content: "\ea3e";
}

.bx-chart:before {
  content: "\ea3f";
}

.bx-chat:before {
  content: "\ea40";
}

.bx-check:before {
  content: "\ea41";
}

.bx-checkbox:before {
  content: "\ea42";
}

.bx-checkbox-checked:before {
  content: "\ea43";
}

.bx-checkbox-minus:before {
  content: "\ea44";
}

.bx-checkbox-square:before {
  content: "\ea45";
}

.bx-check-circle:before {
  content: "\ea46";
}

.bx-check-double:before {
  content: "\ea47";
}

.bx-check-shield:before {
  content: "\ea48";
}

.bx-check-square:before {
  content: "\ea49";
}

.bx-chevron-down:before {
  content: "\ea4a";
}

.bx-chevron-down-circle:before {
  content: "\ea4b";
}

.bx-chevron-down-square:before {
  content: "\ea4c";
}

.bx-chevron-left:before {
  content: "\ea4d";
}

.bx-chevron-left-circle:before {
  content: "\ea4e";
}

.bx-chevron-left-square:before {
  content: "\ea4f";
}

.bx-chevron-right:before {
  content: "\ea50";
}

.bx-chevron-right-circle:before {
  content: "\ea51";
}

.bx-chevron-right-square:before {
  content: "\ea52";
}

.bx-chevrons-down:before {
  content: "\ea53";
}

.bx-chevrons-left:before {
  content: "\ea54";
}

.bx-chevrons-right:before {
  content: "\ea55";
}

.bx-chevrons-up:before {
  content: "\ea56";
}

.bx-chevron-up:before {
  content: "\ea57";
}

.bx-chevron-up-circle:before {
  content: "\ea58";
}

.bx-chevron-up-square:before {
  content: "\ea59";
}

.bx-chip:before {
  content: "\ea5a";
}

.bx-church:before {
  content: "\ea5b";
}

.bx-circle:before {
  content: "\ea5c";
}

.bx-clinic:before {
  content: "\ea5d";
}

.bx-clipboard:before {
  content: "\ea5e";
}

.bx-closet:before {
  content: "\ea5f";
}

.bx-cloud:before {
  content: "\ea60";
}

.bx-cloud-download:before {
  content: "\ea61";
}

.bx-cloud-drizzle:before {
  content: "\ea62";
}

.bx-cloud-lightning:before {
  content: "\ea63";
}

.bx-cloud-light-rain:before {
  content: "\ea64";
}

.bx-cloud-rain:before {
  content: "\ea65";
}

.bx-cloud-snow:before {
  content: "\ea66";
}

.bx-cloud-upload:before {
  content: "\ea67";
}

.bx-code:before {
  content: "\ea68";
}

.bx-code-alt:before {
  content: "\ea69";
}

.bx-code-block:before {
  content: "\ea6a";
}

.bx-code-curly:before {
  content: "\ea6b";
}

.bx-coffee:before {
  content: "\ea6c";
}

.bx-coffee-togo:before {
  content: "\ea6d";
}

.bx-cog:before {
  content: "\ea6e";
}

.bx-coin:before {
  content: "\ea6f";
}

.bx-coin-stack:before {
  content: "\ea70";
}

.bx-collapse:before {
  content: "\ea71";
}

.bx-collection:before {
  content: "\ea72";
}

.bx-color-fill:before {
  content: "\ea73";
}

.bx-columns:before {
  content: "\ea74";
}

.bx-command:before {
  content: "\ea75";
}

.bx-comment:before {
  content: "\ea76";
}

.bx-comment-add:before {
  content: "\ea77";
}

.bx-comment-check:before {
  content: "\ea78";
}

.bx-comment-detail:before {
  content: "\ea79";
}

.bx-comment-dots:before {
  content: "\ea7a";
}

.bx-comment-edit:before {
  content: "\ea7b";
}

.bx-comment-error:before {
  content: "\ea7c";
}

.bx-comment-minus:before {
  content: "\ea7d";
}

.bx-comment-x:before {
  content: "\ea7e";
}

.bx-compass:before {
  content: "\ea7f";
}

.bx-confused:before {
  content: "\ea80";
}

.bx-conversation:before {
  content: "\ea81";
}

.bx-cookie:before {
  content: "\ea82";
}

.bx-cool:before {
  content: "\ea83";
}

.bx-copy:before {
  content: "\ea84";
}

.bx-copy-alt:before {
  content: "\ea85";
}

.bx-copyright:before {
  content: "\ea86";
}

.bx-credit-card:before {
  content: "\ea87";
}

.bx-credit-card-alt:before {
  content: "\ea88";
}

.bx-credit-card-front:before {
  content: "\ea89";
}

.bx-crop:before {
  content: "\ea8a";
}

.bx-crosshair:before {
  content: "\ea8b";
}

.bx-crown:before {
  content: "\ea8c";
}

.bx-cube:before {
  content: "\ea8d";
}

.bx-cube-alt:before {
  content: "\ea8e";
}

.bx-cuboid:before {
  content: "\ea8f";
}

.bx-current-location:before {
  content: "\ea90";
}

.bx-customize:before {
  content: "\ea91";
}

.bx-cut:before {
  content: "\ea92";
}

.bx-cycling:before {
  content: "\ea93";
}

.bx-cylinder:before {
  content: "\ea94";
}

.bx-data:before {
  content: "\ea95";
}

.bx-desktop:before {
  content: "\ea96";
}

.bx-detail:before {
  content: "\ea97";
}

.bx-devices:before {
  content: "\ea98";
}

.bx-dialpad:before {
  content: "\ea99";
}

.bx-dialpad-alt:before {
  content: "\ea9a";
}

.bx-diamond:before {
  content: "\ea9b";
}

.bx-dice-1:before {
  content: "\ea9c";
}

.bx-dice-2:before {
  content: "\ea9d";
}

.bx-dice-3:before {
  content: "\ea9e";
}

.bx-dice-4:before {
  content: "\ea9f";
}

.bx-dice-5:before {
  content: "\eaa0";
}

.bx-dice-6:before {
  content: "\eaa1";
}

.bx-directions:before {
  content: "\eaa2";
}

.bx-disc:before {
  content: "\eaa3";
}

.bx-dish:before {
  content: "\eaa4";
}

.bx-dislike:before {
  content: "\eaa5";
}

.bx-dizzy:before {
  content: "\eaa6";
}

.bx-dna:before {
  content: "\eaa7";
}

.bx-dock-bottom:before {
  content: "\eaa8";
}

.bx-dock-left:before {
  content: "\eaa9";
}

.bx-dock-right:before {
  content: "\eaaa";
}

.bx-dock-top:before {
  content: "\eaab";
}

.bx-dollar:before {
  content: "\eaac";
}

.bx-dollar-circle:before {
  content: "\eaad";
}

.bx-donate-blood:before {
  content: "\eaae";
}

.bx-donate-heart:before {
  content: "\eaaf";
}

.bx-door-open:before {
  content: "\eab0";
}

.bx-dots-horizontal:before {
  content: "\eab1";
}

.bx-dots-horizontal-rounded:before {
  content: "\eab2";
}

.bx-dots-vertical:before {
  content: "\eab3";
}

.bx-dots-vertical-rounded:before {
  content: "\eab4";
}

.bx-doughnut-chart:before {
  content: "\eab5";
}

.bx-down-arrow:before {
  content: "\eab6";
}

.bx-down-arrow-alt:before {
  content: "\eab7";
}

.bx-down-arrow-circle:before {
  content: "\eab8";
}

.bx-download:before {
  content: "\eab9";
}

.bx-downvote:before {
  content: "\eaba";
}

.bx-drink:before {
  content: "\eabb";
}

.bx-droplet:before {
  content: "\eabc";
}

.bx-dumbbell:before {
  content: "\eabd";
}

.bx-duplicate:before {
  content: "\eabe";
}

.bx-edit:before {
  content: "\eabf";
}

.bx-edit-alt:before {
  content: "\eac0";
}

.bx-envelope:before {
  content: "\eac1";
}

.bx-envelope-open:before {
  content: "\eac2";
}

.bx-equalizer:before {
  content: "\eac3";
}

.bx-eraser:before {
  content: "\eac4";
}

.bx-error:before {
  content: "\eac5";
}

.bx-error-alt:before {
  content: "\eac6";
}

.bx-error-circle:before {
  content: "\eac7";
}

.bx-euro:before {
  content: "\eac8";
}

.bx-exclude:before {
  content: "\eac9";
}

.bx-exit:before {
  content: "\eaca";
}

.bx-exit-fullscreen:before {
  content: "\eacb";
}

.bx-expand:before {
  content: "\eacc";
}

.bx-expand-alt:before {
  content: "\eacd";
}

.bx-export:before {
  content: "\eace";
}

.bx-extension:before {
  content: "\eacf";
}

.bx-face:before {
  content: "\ead0";
}

.bx-fast-forward:before {
  content: "\ead1";
}

.bx-fast-forward-circle:before {
  content: "\ead2";
}

.bx-female:before {
  content: "\ead3";
}

.bx-female-sign:before {
  content: "\ead4";
}

.bx-file:before {
  content: "\ead5";
}

.bx-file-blank:before {
  content: "\ead6";
}

.bx-file-find:before {
  content: "\ead7";
}

.bx-film:before {
  content: "\ead8";
}

.bx-filter:before {
  content: "\ead9";
}

.bx-filter-alt:before {
  content: "\eada";
}

.bx-fingerprint:before {
  content: "\eadb";
}

.bx-first-aid:before {
  content: "\eadc";
}

.bx-first-page:before {
  content: "\eadd";
}

.bx-flag:before {
  content: "\eade";
}

.bx-folder:before {
  content: "\eadf";
}

.bx-folder-minus:before {
  content: "\eae0";
}

.bx-folder-open:before {
  content: "\eae1";
}

.bx-folder-plus:before {
  content: "\eae2";
}

.bx-font:before {
  content: "\eae3";
}

.bx-font-color:before {
  content: "\eae4";
}

.bx-font-family:before {
  content: "\eae5";
}

.bx-font-size:before {
  content: "\eae6";
}

.bx-food-menu:before {
  content: "\eae7";
}

.bx-food-tag:before {
  content: "\eae8";
}

.bx-football:before {
  content: "\eae9";
}

.bx-fridge:before {
  content: "\eaea";
}

.bx-fullscreen:before {
  content: "\eaeb";
}

.bx-game:before {
  content: "\eaec";
}

.bx-gas-pump:before {
  content: "\eaed";
}

.bx-ghost:before {
  content: "\eaee";
}

.bx-gift:before {
  content: "\eaef";
}

.bx-git-branch:before {
  content: "\eaf0";
}

.bx-git-commit:before {
  content: "\eaf1";
}

.bx-git-compare:before {
  content: "\eaf2";
}

.bx-git-merge:before {
  content: "\eaf3";
}

.bx-git-pull-request:before {
  content: "\eaf4";
}

.bx-git-repo-forked:before {
  content: "\eaf5";
}

.bx-glasses:before {
  content: "\eaf6";
}

.bx-glasses-alt:before {
  content: "\eaf7";
}

.bx-globe:before {
  content: "\eaf8";
}

.bx-globe-alt:before {
  content: "\eaf9";
}

.bx-grid:before {
  content: "\eafa";
}

.bx-grid-alt:before {
  content: "\eafb";
}

.bx-grid-horizontal:before {
  content: "\eafc";
}

.bx-grid-small:before {
  content: "\eafd";
}

.bx-grid-vertical:before {
  content: "\eafe";
}

.bx-group:before {
  content: "\eaff";
}

.bx-handicap:before {
  content: "\eb00";
}

.bx-happy:before {
  content: "\eb01";
}

.bx-happy-alt:before {
  content: "\eb02";
}

.bx-happy-beaming:before {
  content: "\eb03";
}

.bx-happy-heart-eyes:before {
  content: "\eb04";
}

.bx-hash:before {
  content: "\eb05";
}

.bx-hdd:before {
  content: "\eb06";
}

.bx-heading:before {
  content: "\eb07";
}

.bx-headphone:before {
  content: "\eb08";
}

.bx-health:before {
  content: "\eb09";
}

.bx-heart:before {
  content: "\eb0a";
}

.bx-heart-circle:before {
  content: "\eb0b";
}

.bx-heart-square:before {
  content: "\eb0c";
}

.bx-help-circle:before {
  content: "\eb0d";
}

.bx-hide:before {
  content: "\eb0e";
}

.bx-highlight:before {
  content: "\eb0f";
}

.bx-history:before {
  content: "\eb10";
}

.bx-hive:before {
  content: "\eb11";
}

.bx-home:before {
  content: "\eb12";
}

.bx-home-alt:before {
  content: "\eb13";
}

.bx-home-circle:before {
  content: "\eb14";
}

.bx-home-heart:before {
  content: "\eb15";
}

.bx-home-smile:before {
  content: "\eb16";
}

.bx-horizontal-center:before {
  content: "\eb17";
}

.bx-hotel:before {
  content: "\eb18";
}

.bx-hourglass:before {
  content: "\eb19";
}

.bx-id-card:before {
  content: "\eb1a";
}

.bx-image:before {
  content: "\eb1b";
}

.bx-image-add:before {
  content: "\eb1c";
}

.bx-image-alt:before {
  content: "\eb1d";
}

.bx-images:before {
  content: "\eb1e";
}

.bx-import:before {
  content: "\eb1f";
}

.bx-infinite:before {
  content: "\eb20";
}

.bx-info-circle:before {
  content: "\eb21";
}

.bx-info-square:before {
  content: "\eb22";
}

.bx-intersect:before {
  content: "\eb23";
}

.bx-italic:before {
  content: "\eb24";
}

.bx-joystick:before {
  content: "\eb25";
}

.bx-joystick-alt:before {
  content: "\eb26";
}

.bx-joystick-button:before {
  content: "\eb27";
}

.bx-key:before {
  content: "\eb28";
}

.bx-label:before {
  content: "\eb29";
}

.bx-landscape:before {
  content: "\eb2a";
}

.bx-laptop:before {
  content: "\eb2b";
}

.bx-last-page:before {
  content: "\eb2c";
}

.bx-laugh:before {
  content: "\eb2d";
}

.bx-layer:before {
  content: "\eb2e";
}

.bx-layer-minus:before {
  content: "\eb2f";
}

.bx-layer-plus:before {
  content: "\eb30";
}

.bx-layout:before {
  content: "\eb31";
}

.bx-left-arrow:before {
  content: "\eb32";
}

.bx-left-arrow-alt:before {
  content: "\eb33";
}

.bx-left-arrow-circle:before {
  content: "\eb34";
}

.bx-left-down-arrow-circle:before {
  content: "\eb35";
}

.bx-left-indent:before {
  content: "\eb36";
}

.bx-left-top-arrow-circle:before {
  content: "\eb37";
}

.bx-library:before {
  content: "\eb38";
}

.bx-like:before {
  content: "\eb39";
}

.bx-line-chart:before {
  content: "\eb3a";
}

.bx-line-chart-down:before {
  content: "\eb3b";
}

.bx-link:before {
  content: "\eb3c";
}

.bx-link-alt:before {
  content: "\eb3d";
}

.bx-link-external:before {
  content: "\eb3e";
}

.bx-lira:before {
  content: "\eb3f";
}

.bx-list-check:before {
  content: "\eb40";
}

.bx-list-minus:before {
  content: "\eb41";
}

.bx-list-ol:before {
  content: "\eb42";
}

.bx-list-plus:before {
  content: "\eb43";
}

.bx-list-ul:before {
  content: "\eb44";
}

.bx-loader:before {
  content: "\eb45";
}

.bx-loader-alt:before {
  content: "\eb46";
}

.bx-loader-circle:before {
  content: "\eb47";
}

.bx-location-plus:before {
  content: "\eb48";
}

.bx-lock:before {
  content: "\eb49";
}

.bx-lock-alt:before {
  content: "\eb4a";
}

.bx-lock-open:before {
  content: "\eb4b";
}

.bx-lock-open-alt:before {
  content: "\eb4c";
}

.bx-log-in:before {
  content: "\eb4d";
}

.bx-log-in-circle:before {
  content: "\eb4e";
}

.bx-log-out:before {
  content: "\eb4f";
}

.bx-log-out-circle:before {
  content: "\eb50";
}

.bx-low-vision:before {
  content: "\eb51";
}

.bx-magnet:before {
  content: "\eb52";
}

.bx-mail-send:before {
  content: "\eb53";
}

.bx-male:before {
  content: "\eb54";
}

.bx-male-sign:before {
  content: "\eb55";
}

.bx-map:before {
  content: "\eb56";
}

.bx-map-alt:before {
  content: "\eb57";
}

.bx-map-pin:before {
  content: "\eb58";
}

.bx-mask:before {
  content: "\eb59";
}

.bx-medal:before {
  content: "\eb5a";
}

.bx-meh:before {
  content: "\eb5b";
}

.bx-meh-alt:before {
  content: "\eb5c";
}

.bx-meh-blank:before {
  content: "\eb5d";
}

.bx-memory-card:before {
  content: "\eb5e";
}

.bx-menu:before {
  content: "\eb5f";
}

.bx-menu-alt-left:before {
  content: "\ef5b";
}

.bx-menu-alt-right:before {
  content: "\eb61";
}

.bx-merge:before {
  content: "\eb62";
}

.bx-message:before {
  content: "\eb63";
}

.bx-message-add:before {
  content: "\eb64";
}

.bx-message-alt:before {
  content: "\eb65";
}

.bx-message-alt-add:before {
  content: "\eb66";
}

.bx-message-alt-check:before {
  content: "\eb67";
}

.bx-message-alt-detail:before {
  content: "\eb68";
}

.bx-message-alt-dots:before {
  content: "\eb69";
}

.bx-message-alt-edit:before {
  content: "\eb6a";
}

.bx-message-alt-error:before {
  content: "\eb6b";
}

.bx-message-alt-minus:before {
  content: "\eb6c";
}

.bx-message-alt-x:before {
  content: "\eb6d";
}

.bx-message-check:before {
  content: "\eb6e";
}

.bx-message-detail:before {
  content: "\eb6f";
}

.bx-message-dots:before {
  content: "\eb70";
}

.bx-message-edit:before {
  content: "\eb71";
}

.bx-message-error:before {
  content: "\eb72";
}

.bx-message-minus:before {
  content: "\eb73";
}

.bx-message-rounded:before {
  content: "\eb74";
}

.bx-message-rounded-add:before {
  content: "\eb75";
}

.bx-message-rounded-check:before {
  content: "\eb76";
}

.bx-message-rounded-detail:before {
  content: "\eb77";
}

.bx-message-rounded-dots:before {
  content: "\eb78";
}

.bx-message-rounded-edit:before {
  content: "\ef5c";
}

.bx-message-rounded-error:before {
  content: "\eb7a";
}

.bx-message-rounded-minus:before {
  content: "\eb7b";
}

.bx-message-rounded-x:before {
  content: "\eb7c";
}

.bx-message-square:before {
  content: "\eb7d";
}

.bx-message-square-add:before {
  content: "\eb7e";
}

.bx-message-square-check:before {
  content: "\eb7f";
}

.bx-message-square-detail:before {
  content: "\eb80";
}

.bx-message-square-dots:before {
  content: "\eb81";
}

.bx-message-square-edit:before {
  content: "\eb82";
}

.bx-message-square-error:before {
  content: "\eb83";
}

.bx-message-square-minus:before {
  content: "\eb84";
}

.bx-message-square-x:before {
  content: "\eb85";
}

.bx-message-x:before {
  content: "\eb86";
}

.bx-meteor:before {
  content: "\eb87";
}

.bx-microchip:before {
  content: "\eb88";
}

.bx-microphone:before {
  content: "\eb89";
}

.bx-microphone-off:before {
  content: "\eb8a";
}

.bx-minus:before {
  content: "\eb8b";
}

.bx-minus-back:before {
  content: "\eb8c";
}

.bx-minus-circle:before {
  content: "\eb8d";
}

.bx-minus-front:before {
  content: "\eb8e";
}

.bx-mobile:before {
  content: "\eb8f";
}

.bx-mobile-alt:before {
  content: "\eb90";
}

.bx-mobile-landscape:before {
  content: "\eb91";
}

.bx-mobile-vibration:before {
  content: "\ef5d";
}

.bx-money:before {
  content: "\eb93";
}

.bx-moon:before {
  content: "\eb94";
}

.bx-mouse:before {
  content: "\eb95";
}

.bx-mouse-alt:before {
  content: "\eb96";
}

.bx-move:before {
  content: "\eb97";
}

.bx-move-horizontal:before {
  content: "\eb98";
}

.bx-move-vertical:before {
  content: "\eb99";
}

.bx-movie:before {
  content: "\eb9a";
}

.bx-movie-play:before {
  content: "\eb9b";
}

.bx-music:before {
  content: "\eb9c";
}

.bx-navigation:before {
  content: "\eb9d";
}

.bx-network-chart:before {
  content: "\eb9e";
}

.bx-news:before {
  content: "\eb9f";
}

.bx-no-entry:before {
  content: "\eba0";
}

.bx-note:before {
  content: "\eba1";
}

.bx-notepad:before {
  content: "\eba2";
}

.bx-notification:before {
  content: "\eba3";
}

.bx-notification-off:before {
  content: "\eba4";
}

.bx-outline:before {
  content: "\eba5";
}

.bx-package:before {
  content: "\eba6";
}

.bx-paint:before {
  content: "\eba7";
}

.bx-paint-roll:before {
  content: "\eba8";
}

.bx-palette:before {
  content: "\eba9";
}

.bx-paperclip:before {
  content: "\ebaa";
}

.bx-paper-plane:before {
  content: "\ef61";
}

.bx-paragraph:before {
  content: "\ebac";
}

.bx-paste:before {
  content: "\ebad";
}

.bx-pause:before {
  content: "\ebae";
}

.bx-pause-circle:before {
  content: "\ebaf";
}

.bx-pen:before {
  content: "\ebb0";
}

.bx-pencil:before {
  content: "\ebb1";
}

.bx-phone:before {
  content: "\ebb2";
}

.bx-phone-call:before {
  content: "\ebb3";
}

.bx-phone-incoming:before {
  content: "\ebb4";
}

.bx-phone-off:before {
  content: "\ebb5";
}

.bx-phone-outgoing:before {
  content: "\ebb6";
}

.bx-photo-album:before {
  content: "\ebb7";
}

.bx-pie-chart:before {
  content: "\ebb8";
}

.bx-pie-chart-alt:before {
  content: "\ebb9";
}

.bx-pie-chart-alt-2:before {
  content: "\ebba";
}

.bx-pin:before {
  content: "\ebbb";
}

.bx-planet:before {
  content: "\ebbc";
}

.bx-play:before {
  content: "\ebbd";
}

.bx-play-circle:before {
  content: "\ebbe";
}

.bx-plug:before {
  content: "\ebbf";
}

.bx-plus:before {
  content: "\ebc0";
}

.bx-plus-circle:before {
  content: "\ebc1";
}

.bx-plus-medical:before {
  content: "\ebc2";
}

.bx-podcast:before {
  content: "\ebc3";
}

.bx-pointer:before {
  content: "\ef5e";
}

.bx-poll:before {
  content: "\ebc5";
}

.bx-polygon:before {
  content: "\ebc6";
}

.bx-pound:before {
  content: "\ebc7";
}

.bx-power-off:before {
  content: "\ebc8";
}

.bx-printer:before {
  content: "\ebc9";
}

.bx-pulse:before {
  content: "\ebca";
}

.bx-purchase-tag:before {
  content: "\ebcb";
}

.bx-purchase-tag-alt:before {
  content: "\ebcc";
}

.bx-pyramid:before {
  content: "\ebcd";
}

.bx-qr:before {
  content: "\ebce";
}

.bx-qr-scan:before {
  content: "\ebcf";
}

.bx-question-mark:before {
  content: "\ebd0";
}

.bx-radar:before {
  content: "\ebd1";
}

.bx-radio:before {
  content: "\ebd2";
}

.bx-radio-circle:before {
  content: "\ebd3";
}

.bx-radio-circle-marked:before {
  content: "\ebd4";
}

.bx-receipt:before {
  content: "\ebd5";
}

.bx-rectangle:before {
  content: "\ebd6";
}

.bx-recycle:before {
  content: "\ebd7";
}

.bx-redo:before {
  content: "\ebd8";
}

.bx-refresh:before {
  content: "\ebd9";
}

.bx-registered:before {
  content: "\ebda";
}

.bx-rename:before {
  content: "\ebdb";
}

.bx-repeat:before {
  content: "\ebdc";
}

.bx-reply:before {
  content: "\ef5f";
}

.bx-reply-all:before {
  content: "\ebde";
}

.bx-repost:before {
  content: "\ebdf";
}

.bx-reset:before {
  content: "\ebe0";
}

.bx-restaurant:before {
  content: "\ebe1";
}

.bx-revision:before {
  content: "\ebe2";
}

.bx-rewind:before {
  content: "\ebe3";
}

.bx-rewind-circle:before {
  content: "\ebe4";
}

.bx-right-arrow:before {
  content: "\ebe5";
}

.bx-right-arrow-alt:before {
  content: "\ebe6";
}

.bx-right-arrow-circle:before {
  content: "\ebe7";
}

.bx-right-down-arrow-circle:before {
  content: "\ebe8";
}

.bx-right-indent:before {
  content: "\ebe9";
}

.bx-right-top-arrow-circle:before {
  content: "\ebea";
}

.bx-rocket:before {
  content: "\ebeb";
}

.bx-rotate-left:before {
  content: "\ebec";
}

.bx-rotate-right:before {
  content: "\ebed";
}

.bx-rss:before {
  content: "\ebee";
}

.bx-ruble:before {
  content: "\ebef";
}

.bx-ruler:before {
  content: "\ebf0";
}

.bx-run:before {
  content: "\ebf1";
}

.bx-rupee:before {
  content: "\ebf2";
}

.bx-sad:before {
  content: "\ebf3";
}

.bx-save:before {
  content: "\ebf4";
}

.bx-scan:before {
  content: "\ebf5";
}

.bx-screenshot:before {
  content: "\ef60";
}

.bx-search:before {
  content: "\ebf7";
}

.bx-search-alt:before {
  content: "\ebf8";
}

.bx-search-alt-2:before {
  content: "\ebf9";
}

.bx-selection:before {
  content: "\ebfa";
}

.bx-select-multiple:before {
  content: "\ebfb";
}

.bx-send:before {
  content: "\ebfc";
}

.bx-server:before {
  content: "\ebfd";
}

.bx-shape-circle:before {
  content: "\ebfe";
}

.bx-shape-polygon:before {
  content: "\ebff";
}

.bx-shape-square:before {
  content: "\ec00";
}

.bx-shape-triangle:before {
  content: "\ec01";
}

.bx-share:before {
  content: "\ec02";
}

.bx-share-alt:before {
  content: "\ec03";
}

.bx-shekel:before {
  content: "\ec04";
}

.bx-shield:before {
  content: "\ec05";
}

.bx-shield-alt:before {
  content: "\ec06";
}

.bx-shield-alt-2:before {
  content: "\ec07";
}

.bx-shield-quarter:before {
  content: "\ec08";
}

.bx-shield-x:before {
  content: "\ec09";
}

.bx-shocked:before {
  content: "\ec0a";
}

.bx-shopping-bag:before {
  content: "\ec0b";
}

.bx-show:before {
  content: "\ec0c";
}

.bx-show-alt:before {
  content: "\ec0d";
}

.bx-shuffle:before {
  content: "\ec0e";
}

.bx-sidebar:before {
  content: "\ec0f";
}

.bx-sitemap:before {
  content: "\ec10";
}

.bx-skip-next:before {
  content: "\ec11";
}

.bx-skip-next-circle:before {
  content: "\ec12";
}

.bx-skip-previous:before {
  content: "\ec13";
}

.bx-skip-previous-circle:before {
  content: "\ec14";
}

.bx-sleepy:before {
  content: "\ec15";
}

.bx-slider:before {
  content: "\ec16";
}

.bx-slider-alt:before {
  content: "\ec17";
}

.bx-slideshow:before {
  content: "\ec18";
}

.bx-smile:before {
  content: "\ec19";
}

.bx-sort:before {
  content: "\ec1a";
}

.bx-sort-alt-2:before {
  content: "\ec1b";
}

.bx-sort-a-z:before {
  content: "\ec1c";
}

.bx-sort-down:before {
  content: "\ec1d";
}

.bx-sort-up:before {
  content: "\ec1e";
}

.bx-sort-z-a:before {
  content: "\ec1f";
}

.bx-spa:before {
  content: "\ec20";
}

.bx-space-bar:before {
  content: "\ec21";
}

.bx-speaker:before {
  content: "\ec22";
}

.bx-spray-can:before {
  content: "\ec23";
}

.bx-spreadsheet:before {
  content: "\ec24";
}

.bx-square:before {
  content: "\ec25";
}

.bx-square-rounded:before {
  content: "\ec26";
}

.bx-star:before {
  content: "\ec27";
}

.bx-station:before {
  content: "\ec28";
}

.bx-stats:before {
  content: "\ec29";
}

.bx-sticker:before {
  content: "\ec2a";
}

.bx-stop:before {
  content: "\ec2b";
}

.bx-stop-circle:before {
  content: "\ec2c";
}

.bx-stopwatch:before {
  content: "\ec2d";
}

.bx-store:before {
  content: "\ec2e";
}

.bx-store-alt:before {
  content: "\ec2f";
}

.bx-street-view:before {
  content: "\ec30";
}

.bx-strikethrough:before {
  content: "\ec31";
}

.bx-subdirectory-left:before {
  content: "\ec32";
}

.bx-subdirectory-right:before {
  content: "\ec33";
}

.bx-sun:before {
  content: "\ec34";
}

.bx-support:before {
  content: "\ec35";
}

.bx-swim:before {
  content: "\ec36";
}

.bx-sync:before {
  content: "\ec37";
}

.bx-tab:before {
  content: "\ec38";
}

.bx-table:before {
  content: "\ec39";
}

.bx-tachometer:before {
  content: "\ec3a";
}

.bx-tag:before {
  content: "\ec3b";
}

.bx-tag-alt:before {
  content: "\ec3c";
}

.bx-target-lock:before {
  content: "\ec3d";
}

.bx-task:before {
  content: "\ec3e";
}

.bx-task-x:before {
  content: "\ec3f";
}

.bx-taxi:before {
  content: "\ec40";
}

.bx-tennis-ball:before {
  content: "\ec41";
}

.bx-terminal:before {
  content: "\ec42";
}

.bx-test-tube:before {
  content: "\ec43";
}

.bx-text:before {
  content: "\ec44";
}

.bx-time:before {
  content: "\ec45";
}

.bx-time-five:before {
  content: "\ec46";
}

.bx-timer:before {
  content: "\ec47";
}

.bx-tired:before {
  content: "\ec48";
}

.bx-toggle-left:before {
  content: "\ec49";
}

.bx-toggle-right:before {
  content: "\ec4a";
}

.bx-tone:before {
  content: "\ec4b";
}

.bx-traffic-cone:before {
  content: "\ec4c";
}

.bx-train:before {
  content: "\ec4d";
}

.bx-transfer:before {
  content: "\ec4e";
}

.bx-transfer-alt:before {
  content: "\ec4f";
}

.bx-trash:before {
  content: "\ec50";
}

.bx-trash-alt:before {
  content: "\ec51";
}

.bx-trending-down:before {
  content: "\ec52";
}

.bx-trending-up:before {
  content: "\ec53";
}

.bx-trim:before {
  content: "\ec54";
}

.bx-trip:before {
  content: "\ec55";
}

.bx-trophy:before {
  content: "\ec56";
}

.bx-tv:before {
  content: "\ec57";
}

.bx-underline:before {
  content: "\ec58";
}

.bx-undo:before {
  content: "\ec59";
}

.bx-unite:before {
  content: "\ec5a";
}

.bx-unlink:before {
  content: "\ec5b";
}

.bx-up-arrow:before {
  content: "\ec5c";
}

.bx-up-arrow-alt:before {
  content: "\ec5d";
}

.bx-up-arrow-circle:before {
  content: "\ec5e";
}

.bx-upload:before {
  content: "\ec5f";
}

.bx-upside-down:before {
  content: "\ec60";
}

.bx-upvote:before {
  content: "\ec61";
}

.bx-usb:before {
  content: "\ec62";
}

.bx-user:before {
  content: "\ec63";
}

.bx-user-check:before {
  content: "\ec64";
}

.bx-user-circle:before {
  content: "\ec65";
}

.bx-user-minus:before {
  content: "\ec66";
}

.bx-user-pin:before {
  content: "\ec67";
}

.bx-user-plus:before {
  content: "\ec68";
}

.bx-user-voice:before {
  content: "\ec69";
}

.bx-user-x:before {
  content: "\ec6a";
}

.bx-vector:before {
  content: "\ec6b";
}

.bx-vertical-center:before {
  content: "\ec6c";
}

.bx-vial:before {
  content: "\ec6d";
}

.bx-video:before {
  content: "\ec6e";
}

.bx-video-off:before {
  content: "\ec6f";
}

.bx-video-plus:before {
  content: "\ec70";
}

.bx-video-recording:before {
  content: "\ec71";
}

.bx-voicemail:before {
  content: "\ec72";
}

.bx-volume:before {
  content: "\ec73";
}

.bx-volume-full:before {
  content: "\ec74";
}

.bx-volume-low:before {
  content: "\ec75";
}

.bx-volume-mute:before {
  content: "\ec76";
}

.bx-walk:before {
  content: "\ec77";
}

.bx-wallet:before {
  content: "\ec78";
}

.bx-wallet-alt:before {
  content: "\ec79";
}

.bx-water:before {
  content: "\ec7a";
}

.bx-webcam:before {
  content: "\ec7b";
}

.bx-wifi:before {
  content: "\ec7c";
}

.bx-wifi-0:before {
  content: "\ec7d";
}

.bx-wifi-1:before {
  content: "\ec7e";
}

.bx-wifi-2:before {
  content: "\ec7f";
}

.bx-wifi-off:before {
  content: "\ec80";
}

.bx-wind:before {
  content: "\ec81";
}

.bx-window:before {
  content: "\ec82";
}

.bx-window-alt:before {
  content: "\ec83";
}

.bx-window-close:before {
  content: "\ec84";
}

.bx-window-open:before {
  content: "\ec85";
}

.bx-windows:before {
  content: "\ec86";
}

.bx-wine:before {
  content: "\ec87";
}

.bx-wink-smile:before {
  content: "\ec88";
}

.bx-wink-tongue:before {
  content: "\ec89";
}

.bx-won:before {
  content: "\ec8a";
}

.bx-world:before {
  content: "\ec8b";
}

.bx-wrench:before {
  content: "\ec8c";
}

.bx-x:before {
  content: "\ec8d";
}

.bx-x-circle:before {
  content: "\ec8e";
}

.bx-yen:before {
  content: "\ec8f";
}

.bx-zoom-in:before {
  content: "\ec90";
}

.bx-zoom-out:before {
  content: "\ec91";
}

.bxs-party:before {
  content: "\ec92";
}

.bxs-hot:before {
  content: "\ec93";
}

.bxs-droplet:before {
  content: "\ec94";
}

.bxs-cat:before {
  content: "\ec95";
}

.bxs-dog:before {
  content: "\ec96";
}

.bxs-injection:before {
  content: "\ec97";
}

.bxs-leaf:before {
  content: "\ec98";
}

.bxs-add-to-queue:before {
  content: "\ec99";
}

.bxs-adjust:before {
  content: "\ec9a";
}

.bxs-adjust-alt:before {
  content: "\ec9b";
}

.bxs-alarm:before {
  content: "\ec9c";
}

.bxs-alarm-add:before {
  content: "\ec9d";
}

.bxs-alarm-exclamation:before {
  content: "\ec9e";
}

.bxs-alarm-off:before {
  content: "\ec9f";
}

.bxs-alarm-snooze:before {
  content: "\eca0";
}

.bxs-album:before {
  content: "\eca1";
}

.bxs-ambulance:before {
  content: "\eca2";
}

.bxs-analyse:before {
  content: "\eca3";
}

.bxs-angry:before {
  content: "\eca4";
}

.bxs-arch:before {
  content: "\eca5";
}

.bxs-archive:before {
  content: "\eca6";
}

.bxs-archive-in:before {
  content: "\eca7";
}

.bxs-archive-out:before {
  content: "\eca8";
}

.bxs-area:before {
  content: "\eca9";
}

.bxs-arrow-from-bottom:before {
  content: "\ecaa";
}

.bxs-arrow-from-left:before {
  content: "\ecab";
}

.bxs-arrow-from-right:before {
  content: "\ecac";
}

.bxs-arrow-from-top:before {
  content: "\ecad";
}

.bxs-arrow-to-bottom:before {
  content: "\ecae";
}

.bxs-arrow-to-left:before {
  content: "\ecaf";
}

.bxs-arrow-to-right:before {
  content: "\ecb0";
}

.bxs-arrow-to-top:before {
  content: "\ecb1";
}

.bxs-award:before {
  content: "\ecb2";
}

.bxs-baby-carriage:before {
  content: "\ecb3";
}

.bxs-backpack:before {
  content: "\ecb4";
}

.bxs-badge:before {
  content: "\ecb5";
}

.bxs-badge-check:before {
  content: "\ecb6";
}

.bxs-badge-dollar:before {
  content: "\ecb7";
}

.bxs-ball:before {
  content: "\ecb8";
}

.bxs-band-aid:before {
  content: "\ecb9";
}

.bxs-bank:before {
  content: "\ecba";
}

.bxs-bar-chart-alt-2:before {
  content: "\ecbb";
}

.bxs-bar-chart-square:before {
  content: "\ecbc";
}

.bxs-barcode:before {
  content: "\ecbd";
}

.bxs-baseball:before {
  content: "\ecbe";
}

.bxs-basket:before {
  content: "\ecbf";
}

.bxs-basketball:before {
  content: "\ecc0";
}

.bxs-bath:before {
  content: "\ecc1";
}

.bxs-battery:before {
  content: "\ecc2";
}

.bxs-battery-charging:before {
  content: "\ecc3";
}

.bxs-battery-full:before {
  content: "\ecc4";
}

.bxs-battery-low:before {
  content: "\ecc5";
}

.bxs-bed:before {
  content: "\ecc6";
}

.bxs-been-here:before {
  content: "\ecc7";
}

.bxs-beer:before {
  content: "\ecc8";
}

.bxs-bell:before {
  content: "\ecc9";
}

.bxs-bell-minus:before {
  content: "\ecca";
}

.bxs-bell-off:before {
  content: "\eccb";
}

.bxs-bell-plus:before {
  content: "\eccc";
}

.bxs-bell-ring:before {
  content: "\eccd";
}

.bxs-bible:before {
  content: "\ecce";
}

.bxs-binoculars:before {
  content: "\eccf";
}

.bxs-blanket:before {
  content: "\ecd0";
}

.bxs-bolt:before {
  content: "\ecd1";
}

.bxs-bolt-circle:before {
  content: "\ecd2";
}

.bxs-bomb:before {
  content: "\ecd3";
}

.bxs-bone:before {
  content: "\ecd4";
}

.bxs-bong:before {
  content: "\ecd5";
}

.bxs-book:before {
  content: "\ecd6";
}

.bxs-book-add:before {
  content: "\ecd7";
}

.bxs-book-alt:before {
  content: "\ecd8";
}

.bxs-book-bookmark:before {
  content: "\ecd9";
}

.bxs-book-content:before {
  content: "\ecda";
}

.bxs-book-heart:before {
  content: "\ecdb";
}

.bxs-bookmark:before {
  content: "\ecdc";
}

.bxs-bookmark-alt:before {
  content: "\ecdd";
}

.bxs-bookmark-alt-minus:before {
  content: "\ecde";
}

.bxs-bookmark-alt-plus:before {
  content: "\ecdf";
}

.bxs-bookmark-heart:before {
  content: "\ece0";
}

.bxs-bookmark-minus:before {
  content: "\ece1";
}

.bxs-bookmark-plus:before {
  content: "\ece2";
}

.bxs-bookmarks:before {
  content: "\ece3";
}

.bxs-bookmark-star:before {
  content: "\ece4";
}

.bxs-book-open:before {
  content: "\ece5";
}

.bxs-book-reader:before {
  content: "\ece6";
}

.bxs-bot:before {
  content: "\ece7";
}

.bxs-bowling-ball:before {
  content: "\ece8";
}

.bxs-box:before {
  content: "\ece9";
}

.bxs-brain:before {
  content: "\ecea";
}

.bxs-briefcase:before {
  content: "\eceb";
}

.bxs-briefcase-alt:before {
  content: "\ecec";
}

.bxs-briefcase-alt-2:before {
  content: "\eced";
}

.bxs-brightness:before {
  content: "\ecee";
}

.bxs-brightness-half:before {
  content: "\ecef";
}

.bxs-brush:before {
  content: "\ecf0";
}

.bxs-brush-alt:before {
  content: "\ecf1";
}

.bxs-bug:before {
  content: "\ecf2";
}

.bxs-bug-alt:before {
  content: "\ecf3";
}

.bxs-building:before {
  content: "\ecf4";
}

.bxs-building-house:before {
  content: "\ecf5";
}

.bxs-buildings:before {
  content: "\ecf6";
}

.bxs-bulb:before {
  content: "\ecf7";
}

.bxs-bullseye:before {
  content: "\ecf8";
}

.bxs-buoy:before {
  content: "\ecf9";
}

.bxs-bus:before {
  content: "\ecfa";
}

.bxs-business:before {
  content: "\ecfb";
}

.bxs-bus-school:before {
  content: "\ecfc";
}

.bxs-cabinet:before {
  content: "\ecfd";
}

.bxs-cake:before {
  content: "\ecfe";
}

.bxs-calculator:before {
  content: "\ecff";
}

.bxs-calendar:before {
  content: "\ed00";
}

.bxs-calendar-alt:before {
  content: "\ed01";
}

.bxs-calendar-check:before {
  content: "\ed02";
}

.bxs-calendar-edit:before {
  content: "\ed03";
}

.bxs-calendar-event:before {
  content: "\ed04";
}

.bxs-calendar-exclamation:before {
  content: "\ed05";
}

.bxs-calendar-heart:before {
  content: "\ed06";
}

.bxs-calendar-minus:before {
  content: "\ed07";
}

.bxs-calendar-plus:before {
  content: "\ed08";
}

.bxs-calendar-star:before {
  content: "\ed09";
}

.bxs-calendar-week:before {
  content: "\ed0a";
}

.bxs-calendar-x:before {
  content: "\ed0b";
}

.bxs-camera:before {
  content: "\ed0c";
}

.bxs-camera-home:before {
  content: "\ed0d";
}

.bxs-camera-movie:before {
  content: "\ed0e";
}

.bxs-camera-off:before {
  content: "\ed0f";
}

.bxs-camera-plus:before {
  content: "\ed10";
}

.bxs-capsule:before {
  content: "\ed11";
}

.bxs-captions:before {
  content: "\ed12";
}

.bxs-car:before {
  content: "\ed13";
}

.bxs-car-battery:before {
  content: "\ed14";
}

.bxs-car-crash:before {
  content: "\ed15";
}

.bxs-card:before {
  content: "\ed16";
}

.bxs-caret-down-circle:before {
  content: "\ed17";
}

.bxs-caret-down-square:before {
  content: "\ed18";
}

.bxs-caret-left-circle:before {
  content: "\ed19";
}

.bxs-caret-left-square:before {
  content: "\ed1a";
}

.bxs-caret-right-circle:before {
  content: "\ed1b";
}

.bxs-caret-right-square:before {
  content: "\ed1c";
}

.bxs-caret-up-circle:before {
  content: "\ed1d";
}

.bxs-caret-up-square:before {
  content: "\ed1e";
}

.bxs-car-garage:before {
  content: "\ed1f";
}

.bxs-car-mechanic:before {
  content: "\ed20";
}

.bxs-carousel:before {
  content: "\ed21";
}

.bxs-cart:before {
  content: "\ed22";
}

.bxs-cart-add:before {
  content: "\ed23";
}

.bxs-cart-alt:before {
  content: "\ed24";
}

.bxs-cart-download:before {
  content: "\ed25";
}

.bxs-car-wash:before {
  content: "\ed26";
}

.bxs-category:before {
  content: "\ed27";
}

.bxs-category-alt:before {
  content: "\ed28";
}

.bxs-cctv:before {
  content: "\ed29";
}

.bxs-certification:before {
  content: "\ed2a";
}

.bxs-chalkboard:before {
  content: "\ed2b";
}

.bxs-chart:before {
  content: "\ed2c";
}

.bxs-chat:before {
  content: "\ed2d";
}

.bxs-checkbox:before {
  content: "\ed2e";
}

.bxs-checkbox-checked:before {
  content: "\ed2f";
}

.bxs-checkbox-minus:before {
  content: "\ed30";
}

.bxs-check-circle:before {
  content: "\ed31";
}

.bxs-check-shield:before {
  content: "\ed32";
}

.bxs-check-square:before {
  content: "\ed33";
}

.bxs-chess:before {
  content: "\ed34";
}

.bxs-chevron-down:before {
  content: "\ed35";
}

.bxs-chevron-down-circle:before {
  content: "\ed36";
}

.bxs-chevron-down-square:before {
  content: "\ed37";
}

.bxs-chevron-left:before {
  content: "\ed38";
}

.bxs-chevron-left-circle:before {
  content: "\ed39";
}

.bxs-chevron-left-square:before {
  content: "\ed3a";
}

.bxs-chevron-right:before {
  content: "\ed3b";
}

.bxs-chevron-right-circle:before {
  content: "\ed3c";
}

.bxs-chevron-right-square:before {
  content: "\ed3d";
}

.bxs-chevrons-down:before {
  content: "\ed3e";
}

.bxs-chevrons-left:before {
  content: "\ed3f";
}

.bxs-chevrons-right:before {
  content: "\ed40";
}

.bxs-chevrons-up:before {
  content: "\ed41";
}

.bxs-chevron-up:before {
  content: "\ed42";
}

.bxs-chevron-up-circle:before {
  content: "\ed43";
}

.bxs-chevron-up-square:before {
  content: "\ed44";
}

.bxs-chip:before {
  content: "\ed45";
}

.bxs-church:before {
  content: "\ed46";
}

.bxs-circle:before {
  content: "\ed47";
}

.bxs-city:before {
  content: "\ed48";
}

.bxs-clinic:before {
  content: "\ed49";
}

.bxs-cloud:before {
  content: "\ed4a";
}

.bxs-cloud-download:before {
  content: "\ed4b";
}

.bxs-cloud-lightning:before {
  content: "\ed4c";
}

.bxs-cloud-rain:before {
  content: "\ed4d";
}

.bxs-cloud-upload:before {
  content: "\ed4e";
}

.bxs-coffee:before {
  content: "\ed4f";
}

.bxs-coffee-alt:before {
  content: "\ed50";
}

.bxs-coffee-togo:before {
  content: "\ed51";
}

.bxs-cog:before {
  content: "\ed52";
}

.bxs-coin:before {
  content: "\ed53";
}

.bxs-coin-stack:before {
  content: "\ed54";
}

.bxs-collection:before {
  content: "\ed55";
}

.bxs-color-fill:before {
  content: "\ed56";
}

.bxs-comment:before {
  content: "\ed57";
}

.bxs-comment-add:before {
  content: "\ed58";
}

.bxs-comment-check:before {
  content: "\ed59";
}

.bxs-comment-detail:before {
  content: "\ed5a";
}

.bxs-comment-dots:before {
  content: "\ed5b";
}

.bxs-comment-edit:before {
  content: "\ed5c";
}

.bxs-comment-error:before {
  content: "\ed5d";
}

.bxs-comment-minus:before {
  content: "\ed5e";
}

.bxs-comment-x:before {
  content: "\ed5f";
}

.bxs-compass:before {
  content: "\ed60";
}

.bxs-component:before {
  content: "\ed61";
}

.bxs-confused:before {
  content: "\ed62";
}

.bxs-contact:before {
  content: "\ed63";
}

.bxs-conversation:before {
  content: "\ed64";
}

.bxs-cookie:before {
  content: "\ed65";
}

.bxs-cool:before {
  content: "\ed66";
}

.bxs-copy:before {
  content: "\ed67";
}

.bxs-copy-alt:before {
  content: "\ed68";
}

.bxs-copyright:before {
  content: "\ed69";
}

.bxs-coupon:before {
  content: "\ed6a";
}

.bxs-credit-card:before {
  content: "\ed6b";
}

.bxs-credit-card-alt:before {
  content: "\ed6c";
}

.bxs-credit-card-front:before {
  content: "\ed6d";
}

.bxs-crop:before {
  content: "\ed6e";
}

.bxs-crown:before {
  content: "\ed6f";
}

.bxs-cube:before {
  content: "\ed70";
}

.bxs-cube-alt:before {
  content: "\ed71";
}

.bxs-cuboid:before {
  content: "\ed72";
}

.bxs-customize:before {
  content: "\ed73";
}

.bxs-cylinder:before {
  content: "\ed74";
}

.bxs-dashboard:before {
  content: "\ed75";
}

.bxs-data:before {
  content: "\ed76";
}

.bxs-detail:before {
  content: "\ed77";
}

.bxs-devices:before {
  content: "\ed78";
}

.bxs-diamond:before {
  content: "\ed79";
}

.bxs-dice-1:before {
  content: "\ed7a";
}

.bxs-dice-2:before {
  content: "\ed7b";
}

.bxs-dice-3:before {
  content: "\ed7c";
}

.bxs-dice-4:before {
  content: "\ed7d";
}

.bxs-dice-5:before {
  content: "\ed7e";
}

.bxs-dice-6:before {
  content: "\ed7f";
}

.bxs-direction-left:before {
  content: "\ed80";
}

.bxs-direction-right:before {
  content: "\ed81";
}

.bxs-directions:before {
  content: "\ed82";
}

.bxs-disc:before {
  content: "\ed83";
}

.bxs-discount:before {
  content: "\ed84";
}

.bxs-dish:before {
  content: "\ed85";
}

.bxs-dislike:before {
  content: "\ed86";
}

.bxs-dizzy:before {
  content: "\ed87";
}

.bxs-dock-bottom:before {
  content: "\ed88";
}

.bxs-dock-left:before {
  content: "\ed89";
}

.bxs-dock-right:before {
  content: "\ed8a";
}

.bxs-dock-top:before {
  content: "\ed8b";
}

.bxs-dollar-circle:before {
  content: "\ed8c";
}

.bxs-donate-blood:before {
  content: "\ed8d";
}

.bxs-donate-heart:before {
  content: "\ed8e";
}

.bxs-door-open:before {
  content: "\ed8f";
}

.bxs-doughnut-chart:before {
  content: "\ed90";
}

.bxs-down-arrow:before {
  content: "\ed91";
}

.bxs-down-arrow-alt:before {
  content: "\ed92";
}

.bxs-down-arrow-circle:before {
  content: "\ed93";
}

.bxs-down-arrow-square:before {
  content: "\ed94";
}

.bxs-download:before {
  content: "\ed95";
}

.bxs-downvote:before {
  content: "\ed96";
}

.bxs-drink:before {
  content: "\ed97";
}

.bxs-droplet-half:before {
  content: "\ed98";
}

.bxs-dryer:before {
  content: "\ed99";
}

.bxs-duplicate:before {
  content: "\ed9a";
}

.bxs-edit:before {
  content: "\ed9b";
}

.bxs-edit-alt:before {
  content: "\ed9c";
}

.bxs-edit-location:before {
  content: "\ed9d";
}

.bxs-eject:before {
  content: "\ed9e";
}

.bxs-envelope:before {
  content: "\ed9f";
}

.bxs-envelope-open:before {
  content: "\eda0";
}

.bxs-eraser:before {
  content: "\eda1";
}

.bxs-error:before {
  content: "\eda2";
}

.bxs-error-alt:before {
  content: "\eda3";
}

.bxs-error-circle:before {
  content: "\eda4";
}

.bxs-ev-station:before {
  content: "\eda5";
}

.bxs-exit:before {
  content: "\eda6";
}

.bxs-extension:before {
  content: "\eda7";
}

.bxs-eyedropper:before {
  content: "\eda8";
}

.bxs-face:before {
  content: "\eda9";
}

.bxs-face-mask:before {
  content: "\edaa";
}

.bxs-factory:before {
  content: "\edab";
}

.bxs-fast-forward-circle:before {
  content: "\edac";
}

.bxs-file:before {
  content: "\edad";
}

.bxs-file-archive:before {
  content: "\edae";
}

.bxs-file-blank:before {
  content: "\edaf";
}

.bxs-file-css:before {
  content: "\edb0";
}

.bxs-file-doc:before {
  content: "\edb1";
}

.bxs-file-export:before {
  content: "\edb2";
}

.bxs-file-find:before {
  content: "\edb3";
}

.bxs-file-gif:before {
  content: "\edb4";
}

.bxs-file-html:before {
  content: "\edb5";
}

.bxs-file-image:before {
  content: "\edb6";
}

.bxs-file-import:before {
  content: "\edb7";
}

.bxs-file-jpg:before {
  content: "\edb8";
}

.bxs-file-js:before {
  content: "\edb9";
}

.bxs-file-json:before {
  content: "\edba";
}

.bxs-file-md:before {
  content: "\edbb";
}

.bxs-file-pdf:before {
  content: "\edbc";
}

.bxs-file-plus:before {
  content: "\edbd";
}

.bxs-file-png:before {
  content: "\edbe";
}

.bxs-file-txt:before {
  content: "\edbf";
}

.bxs-film:before {
  content: "\edc0";
}

.bxs-filter-alt:before {
  content: "\edc1";
}

.bxs-first-aid:before {
  content: "\edc2";
}

.bxs-flag:before {
  content: "\edc3";
}

.bxs-flag-alt:before {
  content: "\edc4";
}

.bxs-flag-checkered:before {
  content: "\edc5";
}

.bxs-flame:before {
  content: "\edc6";
}

.bxs-flask:before {
  content: "\edc7";
}

.bxs-florist:before {
  content: "\edc8";
}

.bxs-folder:before {
  content: "\edc9";
}

.bxs-folder-minus:before {
  content: "\edca";
}

.bxs-folder-open:before {
  content: "\edcb";
}

.bxs-folder-plus:before {
  content: "\edcc";
}

.bxs-food-menu:before {
  content: "\edcd";
}

.bxs-fridge:before {
  content: "\edce";
}

.bxs-game:before {
  content: "\edcf";
}

.bxs-gas-pump:before {
  content: "\edd0";
}

.bxs-ghost:before {
  content: "\edd1";
}

.bxs-gift:before {
  content: "\edd2";
}

.bxs-graduation:before {
  content: "\edd3";
}

.bxs-grid:before {
  content: "\edd4";
}

.bxs-grid-alt:before {
  content: "\edd5";
}

.bxs-group:before {
  content: "\edd6";
}

.bxs-guitar-amp:before {
  content: "\edd7";
}

.bxs-hand:before {
  content: "\edd8";
}

.bxs-hand-down:before {
  content: "\edd9";
}

.bxs-hand-left:before {
  content: "\edda";
}

.bxs-hand-right:before {
  content: "\eddb";
}

.bxs-hand-up:before {
  content: "\eddc";
}

.bxs-happy:before {
  content: "\eddd";
}

.bxs-happy-alt:before {
  content: "\edde";
}

.bxs-happy-beaming:before {
  content: "\eddf";
}

.bxs-happy-heart-eyes:before {
  content: "\ede0";
}

.bxs-hdd:before {
  content: "\ede1";
}

.bxs-heart:before {
  content: "\ede2";
}

.bxs-heart-circle:before {
  content: "\ede3";
}

.bxs-heart-square:before {
  content: "\ede4";
}

.bxs-help-circle:before {
  content: "\ede5";
}

.bxs-hide:before {
  content: "\ede6";
}

.bxs-home:before {
  content: "\ede7";
}

.bxs-home-circle:before {
  content: "\ede8";
}

.bxs-home-heart:before {
  content: "\ede9";
}

.bxs-home-smile:before {
  content: "\edea";
}

.bxs-hotel:before {
  content: "\edeb";
}

.bxs-hourglass:before {
  content: "\edec";
}

.bxs-hourglass-bottom:before {
  content: "\eded";
}

.bxs-hourglass-top:before {
  content: "\edee";
}

.bxs-id-card:before {
  content: "\edef";
}

.bxs-image:before {
  content: "\edf0";
}

.bxs-image-add:before {
  content: "\edf1";
}

.bxs-image-alt:before {
  content: "\edf2";
}

.bxs-inbox:before {
  content: "\edf3";
}

.bxs-info-circle:before {
  content: "\edf4";
}

.bxs-info-square:before {
  content: "\edf5";
}

.bxs-institution:before {
  content: "\edf6";
}

.bxs-joystick:before {
  content: "\edf7";
}

.bxs-joystick-alt:before {
  content: "\edf8";
}

.bxs-joystick-button:before {
  content: "\edf9";
}

.bxs-key:before {
  content: "\edfa";
}

.bxs-keyboard:before {
  content: "\edfb";
}

.bxs-label:before {
  content: "\edfc";
}

.bxs-landmark:before {
  content: "\edfd";
}

.bxs-landscape:before {
  content: "\edfe";
}

.bxs-laugh:before {
  content: "\edff";
}

.bxs-layer:before {
  content: "\ee00";
}

.bxs-layer-minus:before {
  content: "\ee01";
}

.bxs-layer-plus:before {
  content: "\ee02";
}

.bxs-layout:before {
  content: "\ee03";
}

.bxs-left-arrow:before {
  content: "\ee04";
}

.bxs-left-arrow-alt:before {
  content: "\ee05";
}

.bxs-left-arrow-circle:before {
  content: "\ee06";
}

.bxs-left-arrow-square:before {
  content: "\ee07";
}

.bxs-left-down-arrow-circle:before {
  content: "\ee08";
}

.bxs-left-top-arrow-circle:before {
  content: "\ee09";
}

.bxs-like:before {
  content: "\ee0a";
}

.bxs-location-plus:before {
  content: "\ee0b";
}

.bxs-lock:before {
  content: "\ee0c";
}

.bxs-lock-alt:before {
  content: "\ee0d";
}

.bxs-lock-open:before {
  content: "\ee0e";
}

.bxs-lock-open-alt:before {
  content: "\ee0f";
}

.bxs-log-in:before {
  content: "\ee10";
}

.bxs-log-in-circle:before {
  content: "\ee11";
}

.bxs-log-out:before {
  content: "\ee12";
}

.bxs-log-out-circle:before {
  content: "\ee13";
}

.bxs-low-vision:before {
  content: "\ee14";
}

.bxs-magic-wand:before {
  content: "\ee15";
}

.bxs-magnet:before {
  content: "\ee16";
}

.bxs-map:before {
  content: "\ee17";
}

.bxs-map-alt:before {
  content: "\ee18";
}

.bxs-map-pin:before {
  content: "\ee19";
}

.bxs-mask:before {
  content: "\ee1a";
}

.bxs-medal:before {
  content: "\ee1b";
}

.bxs-megaphone:before {
  content: "\ee1c";
}

.bxs-meh:before {
  content: "\ee1d";
}

.bxs-meh-alt:before {
  content: "\ee1e";
}

.bxs-meh-blank:before {
  content: "\ee1f";
}

.bxs-memory-card:before {
  content: "\ee20";
}

.bxs-message:before {
  content: "\ee21";
}

.bxs-message-add:before {
  content: "\ee22";
}

.bxs-message-alt:before {
  content: "\ee23";
}

.bxs-message-alt-add:before {
  content: "\ee24";
}

.bxs-message-alt-check:before {
  content: "\ee25";
}

.bxs-message-alt-detail:before {
  content: "\ee26";
}

.bxs-message-alt-dots:before {
  content: "\ee27";
}

.bxs-message-alt-edit:before {
  content: "\ee28";
}

.bxs-message-alt-error:before {
  content: "\ee29";
}

.bxs-message-alt-minus:before {
  content: "\ee2a";
}

.bxs-message-alt-x:before {
  content: "\ee2b";
}

.bxs-message-check:before {
  content: "\ee2c";
}

.bxs-message-detail:before {
  content: "\ee2d";
}

.bxs-message-dots:before {
  content: "\ee2e";
}

.bxs-message-edit:before {
  content: "\ee2f";
}

.bxs-message-error:before {
  content: "\ee30";
}

.bxs-message-minus:before {
  content: "\ee31";
}

.bxs-message-rounded:before {
  content: "\ee32";
}

.bxs-message-rounded-add:before {
  content: "\ee33";
}

.bxs-message-rounded-check:before {
  content: "\ee34";
}

.bxs-message-rounded-detail:before {
  content: "\ee35";
}

.bxs-message-rounded-dots:before {
  content: "\ee36";
}

.bxs-message-rounded-edit:before {
  content: "\ee37";
}

.bxs-message-rounded-error:before {
  content: "\ee38";
}

.bxs-message-rounded-minus:before {
  content: "\ee39";
}

.bxs-message-rounded-x:before {
  content: "\ee3a";
}

.bxs-message-square:before {
  content: "\ee3b";
}

.bxs-message-square-add:before {
  content: "\ee3c";
}

.bxs-message-square-check:before {
  content: "\ee3d";
}

.bxs-message-square-detail:before {
  content: "\ee3e";
}

.bxs-message-square-dots:before {
  content: "\ee3f";
}

.bxs-message-square-edit:before {
  content: "\ee40";
}

.bxs-message-square-error:before {
  content: "\ee41";
}

.bxs-message-square-minus:before {
  content: "\ee42";
}

.bxs-message-square-x:before {
  content: "\ee43";
}

.bxs-message-x:before {
  content: "\ee44";
}

.bxs-meteor:before {
  content: "\ee45";
}

.bxs-microchip:before {
  content: "\ee46";
}

.bxs-microphone:before {
  content: "\ee47";
}

.bxs-microphone-alt:before {
  content: "\ee48";
}

.bxs-microphone-off:before {
  content: "\ee49";
}

.bxs-minus-circle:before {
  content: "\ee4a";
}

.bxs-minus-square:before {
  content: "\ee4b";
}

.bxs-mobile:before {
  content: "\ee4c";
}

.bxs-mobile-vibration:before {
  content: "\ee4d";
}

.bxs-moon:before {
  content: "\ee4e";
}

.bxs-mouse:before {
  content: "\ee4f";
}

.bxs-mouse-alt:before {
  content: "\ee50";
}

.bxs-movie:before {
  content: "\ee51";
}

.bxs-movie-play:before {
  content: "\ee52";
}

.bxs-music:before {
  content: "\ee53";
}

.bxs-navigation:before {
  content: "\ee54";
}

.bxs-network-chart:before {
  content: "\ee55";
}

.bxs-news:before {
  content: "\ee56";
}

.bxs-no-entry:before {
  content: "\ee57";
}

.bxs-note:before {
  content: "\ee58";
}

.bxs-notepad:before {
  content: "\ee59";
}

.bxs-notification:before {
  content: "\ee5a";
}

.bxs-notification-off:before {
  content: "\ee5b";
}

.bxs-offer:before {
  content: "\ee5c";
}

.bxs-package:before {
  content: "\ee5d";
}

.bxs-paint:before {
  content: "\ee5e";
}

.bxs-paint-roll:before {
  content: "\ee5f";
}

.bxs-palette:before {
  content: "\ee60";
}

.bxs-paper-plane:before {
  content: "\ee61";
}

.bxs-parking:before {
  content: "\ee62";
}

.bxs-paste:before {
  content: "\ee63";
}

.bxs-pen:before {
  content: "\ee64";
}

.bxs-pencil:before {
  content: "\ee65";
}

.bxs-phone:before {
  content: "\ee66";
}

.bxs-phone-call:before {
  content: "\ee67";
}

.bxs-phone-incoming:before {
  content: "\ee68";
}

.bxs-phone-off:before {
  content: "\ee69";
}

.bxs-phone-outgoing:before {
  content: "\ee6a";
}

.bxs-photo-album:before {
  content: "\ee6b";
}

.bxs-piano:before {
  content: "\ee6c";
}

.bxs-pie-chart:before {
  content: "\ee6d";
}

.bxs-pie-chart-alt:before {
  content: "\ee6e";
}

.bxs-pie-chart-alt-2:before {
  content: "\ee6f";
}

.bxs-pin:before {
  content: "\ee70";
}

.bxs-pizza:before {
  content: "\ee71";
}

.bxs-plane:before {
  content: "\ee72";
}

.bxs-plane-alt:before {
  content: "\ee73";
}

.bxs-plane-land:before {
  content: "\ee74";
}

.bxs-planet:before {
  content: "\ee75";
}

.bxs-plane-take-off:before {
  content: "\ee76";
}

.bxs-playlist:before {
  content: "\ee77";
}

.bxs-plug:before {
  content: "\ee78";
}

.bxs-plus-circle:before {
  content: "\ee79";
}

.bxs-plus-square:before {
  content: "\ee7a";
}

.bxs-pointer:before {
  content: "\ee7b";
}

.bxs-polygon:before {
  content: "\ee7c";
}

.bxs-printer:before {
  content: "\ee7d";
}

.bxs-purchase-tag:before {
  content: "\ee7e";
}

.bxs-purchase-tag-alt:before {
  content: "\ee7f";
}

.bxs-pyramid:before {
  content: "\ee80";
}

.bxs-quote-alt-left:before {
  content: "\ee81";
}

.bxs-quote-alt-right:before {
  content: "\ee82";
}

.bxs-quote-left:before {
  content: "\ee83";
}

.bxs-quote-right:before {
  content: "\ee84";
}

.bxs-quote-single-left:before {
  content: "\ee85";
}

.bxs-quote-single-right:before {
  content: "\ee86";
}

.bxs-radiation:before {
  content: "\ee87";
}

.bxs-radio:before {
  content: "\ee88";
}

.bxs-receipt:before {
  content: "\ee89";
}

.bxs-rectangle:before {
  content: "\ee8a";
}

.bxs-registered:before {
  content: "\ee8b";
}

.bxs-rename:before {
  content: "\ee8c";
}

.bxs-report:before {
  content: "\ee8d";
}

.bxs-rewind-circle:before {
  content: "\ee8e";
}

.bxs-right-arrow:before {
  content: "\ee8f";
}

.bxs-right-arrow-alt:before {
  content: "\ee90";
}

.bxs-right-arrow-circle:before {
  content: "\ee91";
}

.bxs-right-arrow-square:before {
  content: "\ee92";
}

.bxs-right-down-arrow-circle:before {
  content: "\ee93";
}

.bxs-right-top-arrow-circle:before {
  content: "\ee94";
}

.bxs-rocket:before {
  content: "\ee95";
}

.bxs-ruler:before {
  content: "\ee96";
}

.bxs-sad:before {
  content: "\ee97";
}

.bxs-save:before {
  content: "\ee98";
}

.bxs-school:before {
  content: "\ee99";
}

.bxs-search:before {
  content: "\ee9a";
}

.bxs-search-alt-2:before {
  content: "\ee9b";
}

.bxs-select-multiple:before {
  content: "\ee9c";
}

.bxs-send:before {
  content: "\ee9d";
}

.bxs-server:before {
  content: "\ee9e";
}

.bxs-shapes:before {
  content: "\ee9f";
}

.bxs-share:before {
  content: "\eea0";
}

.bxs-share-alt:before {
  content: "\eea1";
}

.bxs-shield:before {
  content: "\eea2";
}

.bxs-shield-alt-2:before {
  content: "\eea3";
}

.bxs-shield-x:before {
  content: "\eea4";
}

.bxs-ship:before {
  content: "\eea5";
}

.bxs-shocked:before {
  content: "\eea6";
}

.bxs-shopping-bag:before {
  content: "\eea7";
}

.bxs-shopping-bag-alt:before {
  content: "\eea8";
}

.bxs-shopping-bags:before {
  content: "\eea9";
}

.bxs-show:before {
  content: "\eeaa";
}

.bxs-skip-next-circle:before {
  content: "\eeab";
}

.bxs-skip-previous-circle:before {
  content: "\eeac";
}

.bxs-skull:before {
  content: "\eead";
}

.bxs-sleepy:before {
  content: "\eeae";
}

.bxs-slideshow:before {
  content: "\eeaf";
}

.bxs-smile:before {
  content: "\eeb0";
}

.bxs-sort-alt:before {
  content: "\eeb1";
}

.bxs-spa:before {
  content: "\eeb2";
}

.bxs-speaker:before {
  content: "\eeb3";
}

.bxs-spray-can:before {
  content: "\eeb4";
}

.bxs-spreadsheet:before {
  content: "\eeb5";
}

.bxs-square:before {
  content: "\eeb6";
}

.bxs-square-rounded:before {
  content: "\eeb7";
}

.bxs-star:before {
  content: "\eeb8";
}

.bxs-star-half:before {
  content: "\eeb9";
}

.bxs-sticker:before {
  content: "\eeba";
}

.bxs-stopwatch:before {
  content: "\eebb";
}

.bxs-store:before {
  content: "\eebc";
}

.bxs-store-alt:before {
  content: "\eebd";
}

.bxs-sun:before {
  content: "\eebe";
}

.bxs-tachometer:before {
  content: "\eebf";
}

.bxs-tag:before {
  content: "\eec0";
}

.bxs-tag-alt:before {
  content: "\eec1";
}

.bxs-tag-x:before {
  content: "\eec2";
}

.bxs-taxi:before {
  content: "\eec3";
}

.bxs-tennis-ball:before {
  content: "\eec4";
}

.bxs-terminal:before {
  content: "\eec5";
}

.bxs-thermometer:before {
  content: "\eec6";
}

.bxs-time:before {
  content: "\eec7";
}

.bxs-time-five:before {
  content: "\eec8";
}

.bxs-timer:before {
  content: "\eec9";
}

.bxs-tired:before {
  content: "\eeca";
}

.bxs-toggle-left:before {
  content: "\eecb";
}

.bxs-toggle-right:before {
  content: "\eecc";
}

.bxs-tone:before {
  content: "\eecd";
}

.bxs-torch:before {
  content: "\eece";
}

.bxs-to-top:before {
  content: "\eecf";
}

.bxs-traffic:before {
  content: "\eed0";
}

.bxs-traffic-barrier:before {
  content: "\eed1";
}

.bxs-traffic-cone:before {
  content: "\eed2";
}

.bxs-train:before {
  content: "\eed3";
}

.bxs-trash:before {
  content: "\eed4";
}

.bxs-trash-alt:before {
  content: "\eed5";
}

.bxs-tree:before {
  content: "\eed6";
}

.bxs-trophy:before {
  content: "\eed7";
}

.bxs-truck:before {
  content: "\eed8";
}

.bxs-t-shirt:before {
  content: "\eed9";
}

.bxs-tv:before {
  content: "\eeda";
}

.bxs-up-arrow:before {
  content: "\eedb";
}

.bxs-up-arrow-alt:before {
  content: "\eedc";
}

.bxs-up-arrow-circle:before {
  content: "\eedd";
}

.bxs-up-arrow-square:before {
  content: "\eede";
}

.bxs-upside-down:before {
  content: "\eedf";
}

.bxs-upvote:before {
  content: "\eee0";
}

.bxs-user:before {
  content: "\eee1";
}

.bxs-user-account:before {
  content: "\eee2";
}

.bxs-user-badge:before {
  content: "\eee3";
}

.bxs-user-check:before {
  content: "\eee4";
}

.bxs-user-circle:before {
  content: "\eee5";
}

.bxs-user-detail:before {
  content: "\eee6";
}

.bxs-user-minus:before {
  content: "\eee7";
}

.bxs-user-pin:before {
  content: "\eee8";
}

.bxs-user-plus:before {
  content: "\eee9";
}

.bxs-user-rectangle:before {
  content: "\eeea";
}

.bxs-user-voice:before {
  content: "\eeeb";
}

.bxs-user-x:before {
  content: "\eeec";
}

.bxs-vector:before {
  content: "\eeed";
}

.bxs-vial:before {
  content: "\eeee";
}

.bxs-video:before {
  content: "\eeef";
}

.bxs-video-off:before {
  content: "\eef0";
}

.bxs-video-plus:before {
  content: "\eef1";
}

.bxs-video-recording:before {
  content: "\eef2";
}

.bxs-videos:before {
  content: "\eef3";
}

.bxs-virus:before {
  content: "\eef4";
}

.bxs-virus-block:before {
  content: "\eef5";
}

.bxs-volume:before {
  content: "\eef6";
}

.bxs-volume-full:before {
  content: "\eef7";
}

.bxs-volume-low:before {
  content: "\eef8";
}

.bxs-volume-mute:before {
  content: "\eef9";
}

.bxs-wallet:before {
  content: "\eefa";
}

.bxs-wallet-alt:before {
  content: "\eefb";
}

.bxs-washer:before {
  content: "\eefc";
}

.bxs-watch:before {
  content: "\eefd";
}

.bxs-watch-alt:before {
  content: "\eefe";
}

.bxs-webcam:before {
  content: "\eeff";
}

.bxs-widget:before {
  content: "\ef00";
}

.bxs-window-alt:before {
  content: "\ef01";
}

.bxs-wine:before {
  content: "\ef02";
}

.bxs-wink-smile:before {
  content: "\ef03";
}

.bxs-wink-tongue:before {
  content: "\ef04";
}

.bxs-wrench:before {
  content: "\ef05";
}

.bxs-x-circle:before {
  content: "\ef06";
}

.bxs-x-square:before {
  content: "\ef07";
}

.bxs-yin-yang:before {
  content: "\ef08";
}

.bxs-zap:before {
  content: "\ef09";
}

.bxs-zoom-in:before {
  content: "\ef0a";
}

.bxs-zoom-out:before {
  content: "\ef0b";
}

input, textarea {
  outline: none;
}

html, body {
  height: 100%;
}

body {
  font-family: "sf-pro-ex", sans-serif;
  font-size: 16px;
  color: #212129;
  min-width: 320px;
  position: relative;
  background: #fff;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Gordita", sans-serif;
  line-height: 1.2 !important;
}

header, footer {
  position: relative;
}

::-webkit-scrollbar {
  width: 6px;
  scrollbar-width: thin;
  scrollbar-color: #F7CD47 #F9FAFB;
}

::-webkit-scrollbar-thumb {
  background-color: #F7CD47;
  border-radius: 8px;
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #F9FAFB;
}

.form-control {
  position: relative;
  width: 100%;
  margin: 15px 0;
}
.form-control.form-control-error .label {
  color: #EA3D30;
}
.form-control.form-control-error .input-control {
  border: 1px solid #F04438;
  color: #EA3D30;
}
.form-control.form-control-disabled .input-control:disabled {
  background: #F0F1F2;
  border-color: #DFE1E8;
  color: #98A2B3;
}
.form-control.form-control-disabled .btn-after {
  color: #98A2B3;
}
.form-control.form-control-md .input-control {
  height: 48px;
  padding: 12px 16px;
  line-height: 1;
  font-size: 18px;
}
.form-control.form-control-md .input-control::placeholder {
  color: #98A2B3;
  font-weight: 400;
}
.form-control.form-control-md .btn-after {
  display: flex;
  top: 14px !important;
}
.form-control.form-control-center .input-control {
  text-align: center;
}
.form-control.form-control-center .input-control::placeholder {
  text-align: center;
}
.form-control.form-control-sm .input-control {
  height: 41px;
  padding: 12px 9px;
  line-height: 1;
  font-size: 16px;
}
.form-control.form-control-sm .input-control::placeholder {
  color: #98A2B3;
  font-weight: 400;
}
.form-control.form-control-sm .btn-after {
  top: 6px !important;
}
.form-control.form-control-btn-before .input-control {
  padding-left: 48px;
}
.form-control.form-control-btn-before .btn-before {
  background: transparent;
  padding: 0;
  outline: none;
  border: none;
  font-size: 24px;
  line-height: 1;
  position: absolute;
  left: 14px;
  top: 12px;
}
.form-control.form-control-btn-after .input-control {
  padding-right: 48px;
}
.form-control.form-control-btn-after .btn-after {
  background: transparent;
  padding: 0;
  outline: none;
  border: none;
  font-size: 24px;
  line-height: 1;
  position: absolute;
  right: 16px;
  top: 12px;
}
.form-control .label {
  font-size: 16px;
  color: #7E8CA0;
  font-weight: 400;
  position: absolute;
  left: 20px;
  top: 17px;
  pointer-events: none;
  transform-origin: left center;
  transition: transform, top 250ms;
  display: inline-block;
}
.form-control .input-control {
  height: 55px;
  width: 100%;
  max-width: 100%;
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  background-color: #FFFFFF;
  font-weight: 500;
  padding: 20px 45px 0 20px;
  line-height: 55px;
  color: #212129;
}
.form-control .input-control-static {
  padding: 0 45px 0 112px;
  text-align: right;
}
.form-control .input-control.input-control-gray {
  background-color: #F0F1F2;
}
.form-control .input-control:focus + .label,
.form-control .input-control:not(:placeholder-shown) + .label {
  top: 9px;
  font-size: 12px;
}
.form-control .input-control-static:focus + .label,
.form-control .input-control-static:not(:placeholder-shown) + .label {
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: 500;
}
.form-control .show-password {
  position: absolute;
  right: 15px;
  top: 13px;
  border: none;
  outline: none;
  background: transparent;
  color: #DFE1E8;
  font-size: 20px;
}

.validation-text {
  margin-left: 8px;
  font-size: 12px;
  margin-top: 2px;
  color: #EA3D30;
}
.validation-text p {
  margin-bottom: 2px;
}

.text-errors {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.text-errors-item {
  font-size: 14px;
  line-height: 16.8px;
  color: #EA3D30;
}
.text-errors-item:first-child {
  margin-top: 8px;
}

.disable-input-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.disable-input-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.disable-input-arrow[type=number] {
  -moz-appearance: textfield;
}

.btn {
  text-decoration: none;
  padding: 0 32px;
  height: 55px;
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  outline: none;
}
.btn:disabled {
  cursor: not-allowed;
}

.btn-primary {
  background: #F7CD47;
  color: #212129;
  border: none;
  cursor: pointer;
  transition: background 0.4s ease-out;
}
.btn-primary:hover {
  background: #f5c016;
}
.btn-primary:active {
  background: #f9da78;
}
.btn-primary:disabled {
  background: #f0f0f0;
  color: #BDBDBD;
}

.btn-success {
  background: #12B76A;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  transition: background 0.4s ease-out;
}
.btn-success:hover {
  background: #10a45f;
}
.btn-success:active {
  background: #14ca75;
}
.btn-success:disabled {
  background: #f0f0f0;
  color: #BDBDBD;
}

.btn-danger {
  background: #F04438;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  transition: background 0.4s ease-out;
}
.btn-danger:hover {
  background: #ef3225;
}
.btn-danger:active {
  background: #f1564b;
}
.btn-danger:disabled {
  background: #f0f0f0;
  color: #BDBDBD;
}

.btn-dark {
  background: #212129;
  color: #FED660;
  border: none;
  cursor: pointer;
  transition: background 0.4s ease-out;
}
.btn-dark:hover {
  background: #18181e;
}
.btn-dark:active {
  background: #2a2a34;
}
.btn-dark:disabled {
  background: #f0f0f0;
  color: #BDBDBD;
}

.btn-solid-mix {
  background: transparent;
  border: 1px solid #EEC02F;
  color: #212129;
  transition: all 0.4s ease-in;
}

.btn-solid-mix-danger {
  background: transparent;
  border: 1px solid #DFE1E8;
  color: #EA3D30;
  transition: all 0.4s ease-in;
}

.btn-solid-gray {
  background: transparent;
  border: 1px solid #DFE1E8;
  color: #212129;
  transition: all 0.4s ease-in;
}

.btn-solid-danger {
  background: transparent;
  border: 1px solid #F04438;
  color: #EA3D30;
  transition: all 0.4s ease-in;
}

.btn-lg {
  height: 50px;
  font-size: 18px;
}

.btn-md {
  height: 43px;
  font-size: 16px;
}

.btn-sm {
  height: 32px;
  font-size: 14px;
}

.btn-direct {
  border-radius: 5px;
}

.badge {
  padding: 15px;
  border-radius: 8px;
  background: transparent;
  border-width: 1px;
  border-style: solid;
}
.badge p {
  line-height: 1.2;
  margin-bottom: 0;
}
.badge.badge-error {
  color: #EA3D30;
  border-color: #EA3D30;
}

.radio {
  appearance: none;
  -webkit-appearance: none;
  background-color: #FFFFFF;
  border: 2px solid #212129;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
}
.radio:checked::after {
  content: "";
  display: block;
  background-color: #212129;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profitability-success {
  color: #12B76A !important;
}
.profitability-danger {
  color: #EA3D30 !important;
}
.profitability-gray {
  color: #98A2B3 !important;
}

.image-container {
  max-width: 100%;
  width: 100%;
}
.image-container img {
  width: 100%;
  height: auto;
}

.skin-visible {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}
.switch .switch-input {
  display: none;
}
.switch .switch-input:checked + .switch-label {
  background: #12B76A;
}
.switch .switch-input:checked + .switch-label::before {
  transform: translateX(20px);
}
.switch .switch-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F0F1F2;
  border-radius: 34px;
  cursor: pointer;
  transition: background 0.3s;
}
.switch .switch-label::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  left: 3px;
  top: 3px;
  background: #FFFFFF;
  transition: transform 0.3s;
}
.switch.switch-danger .switch-input:checked + .switch-label {
  background: #F04438;
}

.remark {
  line-height: 120%;
  position: relative;
  padding-left: 21px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.remark:before {
  content: "";
  width: 5px;
  height: 100%;
  background: #F7CD47;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
}
.remark .remark-item {
  line-height: 19.2px;
  margin: 0;
}
.remark.remark-danger:before {
  background: #F04438;
}

.tabs {
  z-index: 0;
}
.tabs .mat-mdc-tab {
  border-bottom: 1px solid #F2F2F2;
}
.tabs .mat-mdc-tab .mdc-tab-indicator__content--underline {
  transition: none;
  border-color: #212129;
}
.tabs .mat-mdc-tab .mdc-tab__text-label {
  transition: none;
  color: #3A97FB;
}
.tabs .mat-mdc-tab:hover .mdc-tab-indicator__content--underline {
  border-color: #212129 !important;
}
.tabs .mat-mdc-tab:hover .mdc-tab__text-label {
  color: #212129 !important;
}
.tabs .mat-mdc-tab:focus .mdc-tab-indicator__content--underline {
  border-color: #212129 !important;
}
.tabs .mat-mdc-tab:focus .mdc-tab__text-label {
  color: #212129 !important;
}
.tabs .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #212129;
  font-weight: 600;
}

.overlay {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  outline: none;
}

.fade {
  transition: opacity 0.15s linear;
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 21px;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox .checkbox-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #FFFFFF;
  border: 1.5px solid #A5AEBD;
  border-radius: 4px;
}
.checkbox .checkbox-mark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #FFFFFF;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkbox input:checked ~ .checkbox-mark {
  background-color: #212129;
  border: 1.5px solid #212129;
}
.checkbox input:checked ~ .checkbox-mark:after {
  display: block;
}

.code-input {
  --item-shadow-focused: none !important;
  display: flex !important;
  gap: 12px !important;
}
.code-input span {
  padding: 0 !important;
}
.code-input input {
  width: 48px !important;
  border: 1px solid #ECECEC !important;
  height: 64px !important;
  border-radius: 8px !important;
  background: #FFFFFF !important;
  font-weight: 400 !important;
  color: #212129 !important;
  font-size: 36px !important;
}

.switch-theme {
  border-radius: 100px;
  padding: 4px;
  border: 1px solid #F2F2F2;
  cursor: pointer;
  background: #F9FAFB;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-in-out 0.2s;
}
.switch-theme.light {
  background: #F7CD47;
}
.switch-theme.light .moon {
  transform: translateX(100%);
  opacity: 0;
}
.switch-theme.light .sun {
  opacity: 1;
}
.switch-theme.dark .sun {
  transform: translateX(-100%);
  opacity: 0;
}
.switch-theme.dark .moon {
  opacity: 1;
}
.switch-theme .switch-icon {
  font-size: 18px;
  border-radius: 100%;
  line-height: 1;
  padding: 4px;
  transition: ease-in-out 0.2s;
}
.switch-theme .switch-icon.moon {
  color: #212129;
}
.switch-theme .switch-icon.sun {
  color: #FFFFFF;
}

.core-sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 40;
  height: 100%;
  transition: ease-in-out 0.7s;
}
.core-sidebar.open {
  right: 0;
}

.sidebar {
  background: #FFFFFF;
  border-left: 1px solid #F2F2F2;
  width: 100%;
  height: 100vh;
  padding: 24px;
}
.sidebar-sm {
  width: 384px;
  padding: 50px 24px 24px 24px;
  overflow-y: auto;
}
.sidebar-md {
  width: 460px;
  padding: 50px 24px 24px 24px;
  overflow-y: auto;
}
.sidebar-lg {
  width: 500px;
  padding: 50px 24px 24px 24px;
  overflow-y: auto;
}
.sidebar-xl {
  width: 554px;
  padding: 50px 24px 24px 24px;
  overflow-y: auto;
}
.sidebar__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar__header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar__header-title {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
}
.sidebar__header-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.sidebar__content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.sidebar__analytics {
  background: #F9FAFB;
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  padding: 12px 0;
  display: flex;
  justify-content: space-evenly;
}
.sidebar__analytics__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  border-left: 1px solid #DBE0E8;
}
.sidebar__analytics__column:first-child {
  border-left: 1px solid transparent !important;
}
.sidebar__analytics__cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.sidebar__analytics__cell-title {
  font-size: 12px;
  color: #98A2B3;
  text-align: center;
  line-height: 1;
}
.sidebar__analytics__cell-value {
  font-weight: 600;
  text-align: center;
  line-height: 1;
}
.sidebar__orders-title {
  font-size: 18px;
}
.sidebar__orders__table {
  margin-top: 6px;
  max-height: 350px;
  overflow-y: auto;
  padding-right: 10px;
}
.sidebar.sidebar-slTp .sidebar__settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sidebar.sidebar-slTp .sidebar__settings__amount__range .ngx-slider .ngx-slider-pointer {
  background: #EEC02F;
  transform: scale(0.6) !important;
}
.sidebar.sidebar-slTp .sidebar__settings__amount__range .ngx-slider .ngx-slider-pointer:focus {
  outline: none;
}
.sidebar.sidebar-slTp .sidebar__settings__amount__range .ngx-slider .ngx-slider-pointer:after {
  background: #FFFFFF !important;
  width: 10px;
  height: 10px;
  top: 11px;
  left: 11px;
  border-radius: 100%;
}
.sidebar.sidebar-slTp .sidebar__settings__amount__range .ngx-slider .ngx-slider-selection {
  background: #EEC02F;
}
.sidebar.sidebar-slTp .sidebar__settings__amount__range .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: #EEC02F;
}
.sidebar.sidebar-slTp .sidebar__settings__amount__inputs {
  display: flex;
  justify-content: space-between;
  gap: 46px;
}
.sidebar.sidebar-slTp .sidebar__settings__amount__inputs .form-control {
  margin: 0;
}
.sidebar.sidebar-slTp .sidebar__settings__amount__inputs-mark {
  font-size: 16px;
  font-weight: 500;
}
.sidebar.sidebar-slTp .sidebar__settings__slTp {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
}
.sidebar.sidebar-slTp .sidebar__settings__slTp__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sidebar.sidebar-slTp .sidebar__settings__slTp__column__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
.sidebar.sidebar-slTp .sidebar__settings__slTp__column__title-value {
  font-weight: 500;
  line-height: 20.8px;
}
.sidebar.sidebar-slTp .sidebar__settings__slTp__column__inputs-item {
  border-radius: 4px;
  font-weight: 500;
  margin-top: 12px;
  border: 1px solid #F2F2F2;
  text-align: center;
  padding: 0 9px;
  height: 40px;
  width: 100%;
}
.sidebar.sidebar-slTp .sidebar__settings__slTp__column__types {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 32px;
}
.sidebar.sidebar-slTp .sidebar__settings__slTp__column__types-title {
  font-size: 12px;
  line-height: 12px;
  color: #98A2B3;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar.sidebar-slTp .sidebar__btns {
  display: flex;
  gap: 16px;
  margin-top: 40px;
  padding-bottom: 24px;
}
.sidebar.sidebar-slTp .sidebar__btns .btn {
  width: 50%;
}
.sidebar.sidebar-closePosition .sidebar__settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sidebar.sidebar-closePosition .sidebar__settings__amount__range .ngx-slider .ngx-slider-pointer {
  background: #EEC02F;
  transform: scale(0.6) !important;
}
.sidebar.sidebar-closePosition .sidebar__settings__amount__range .ngx-slider .ngx-slider-pointer:focus {
  outline: none;
}
.sidebar.sidebar-closePosition .sidebar__settings__amount__range .ngx-slider .ngx-slider-pointer:after {
  background: #FFFFFF !important;
  width: 10px;
  height: 10px;
  top: 11px;
  left: 11px;
  border-radius: 100%;
}
.sidebar.sidebar-closePosition .sidebar__settings__amount__range .ngx-slider .ngx-slider-selection {
  background: #EEC02F;
}
.sidebar.sidebar-closePosition .sidebar__settings__amount__range .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: #EEC02F;
}
.sidebar.sidebar-closePosition .sidebar__settings__amount__inputs {
  display: flex;
  justify-content: space-between;
  gap: 46px;
}
.sidebar.sidebar-closePosition .sidebar__settings__amount__inputs .form-control {
  margin: 0;
}
.sidebar.sidebar-closePosition .sidebar__settings__amount__inputs-mark {
  font-size: 16px;
  font-weight: 500;
}
.sidebar.sidebar-closePosition .sidebar__settings__order-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.sidebar.sidebar-closePosition .sidebar__settings__order-info .form-control {
  margin: 0;
}
.sidebar.sidebar-closePosition .sidebar__settings__order-info__inner {
  display: flex;
  gap: 12px;
}
.sidebar.sidebar-closePosition .sidebar__settings__order-info-item {
  width: 50%;
}
.sidebar.sidebar-closePosition .sidebar__settings__switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.sidebar-closePosition .sidebar__btns {
  display: flex;
  gap: 16px;
  margin-top: 40px;
  padding-bottom: 24px;
}
.sidebar.sidebar-closePosition .sidebar__btns .btn {
  width: 50%;
}
.sidebar.sidebar-orderDetails .sidebar__type-operation {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: #12B76A;
  padding: 11px 15px;
}
.sidebar.sidebar-orderDetails .sidebar__type-operation-title {
  font-weight: 600;
  color: #FFFFFF;
  line-height: 16px;
}
.sidebar.sidebar-orderDetails .sidebar__type-operation-date {
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 14px;
}
.sidebar.sidebar-orderDetails .sidebar__type-operation-sell {
  background: #F04438;
}
.sidebar.sidebar-orderDetails .sidebar__ticker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}
.sidebar.sidebar-orderDetails .sidebar__ticker__preview {
  display: flex;
  align-items: center;
  gap: 8px;
}
.sidebar.sidebar-orderDetails .sidebar__ticker__preview-image {
  max-width: 40px;
}
.sidebar.sidebar-orderDetails .sidebar__ticker__preview__stub {
  background: #F0F1F2;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar.sidebar-orderDetails .sidebar__ticker__preview__stub-title {
  font-weight: 600;
}
.sidebar.sidebar-orderDetails .sidebar__ticker__preview__description {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.sidebar.sidebar-orderDetails .sidebar__ticker__preview__description-title {
  line-height: 24px;
  font-size: 24px;
  font-weight: 700;
}
.sidebar.sidebar-orderDetails .sidebar__ticker__preview__description-name {
  line-height: 14px;
  font-size: 14px;
  font-weight: 600;
  color: #98A2B3;
}
.sidebar.sidebar-orderDetails .sidebar__ticker-status {
  font-weight: 600;
  line-height: 19.2px;
}
.sidebar.sidebar-orderDetails .sidebar__info-wrapper {
  margin-top: 30px;
}
.sidebar.sidebar-orderDetails .sidebar__info {
  border-top: 1px solid #F2F2F2;
  padding: 26px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.sidebar.sidebar-orderDetails .sidebar__info__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar.sidebar-orderDetails .sidebar__info__row-column {
  display: flex;
  flex-direction: column;
}
.sidebar.sidebar-orderDetails .sidebar__info__row-title {
  line-height: 16px;
}
.sidebar.sidebar-orderDetails .sidebar__info__row-sub-title {
  color: #98A2B3;
  font-size: 14px;
}
.sidebar.sidebar-orderDetails .sidebar__info__row-value {
  font-weight: 600;
  line-height: 16px;
}
.sidebar.sidebar-orderDetails .sidebar__dates {
  border-top: 1px solid #F2F2F2;
  padding: 26px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sidebar.sidebar-orderDetails .sidebar__dates__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar.sidebar-orderDetails .sidebar__dates__row-title {
  line-height: 14px;
  font-size: 14px;
  color: #98A2B3;
}
.sidebar.sidebar-orderDetails .sidebar__dates__row-value {
  line-height: 14px;
  font-size: 14px;
  color: #98A2B3;
}
.sidebar.sidebar-orderDetails .sidebar__btns {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.sidebar.sidebar-orderDetails .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-changeOrder .sidebar__btns {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}
.sidebar.sidebar-changeOrder .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-changeOrder .sidebar__btns-confirm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}
.sidebar.sidebar-changeOrder .sidebar__btns-confirm .btn {
  width: 100%;
}
.sidebar.sidebar-changeOrder .sidebar__info {
  margin-top: 40px;
  border-radius: 8px;
  padding: 16px 20px;
  background: #F9FAFB;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.sidebar.sidebar-changeOrder .sidebar__info__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.sidebar.sidebar-changeOrder .sidebar__info__item-title {
  line-height: 20.8px;
  color: #505055;
}
.sidebar.sidebar-changeOrder .sidebar__info__item-value {
  line-height: 20.8px;
  font-weight: 600;
}
.sidebar.sidebar-changeOrder .sidebar__inputs {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sidebar.sidebar-changeOrder .sidebar__inputs__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.sidebar.sidebar-changeOrder .sidebar__inputs__item-title {
  font-weight: 500;
  line-height: 16px;
}
.sidebar.sidebar-changeOrder .sidebar__inputs__item-line {
  width: 100%;
  height: 1px;
  background: #F2F2F2;
}
.sidebar.sidebar-positionInfo .sidebar__btns {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}
.sidebar.sidebar-positionInfo .sidebar__btns .btn {
  width: 50%;
}
.sidebar.sidebar-positionInfo .sidebar__ticker {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.sidebar-positionInfo .sidebar__ticker__info {
  display: flex;
  align-items: center;
  gap: 8px;
}
.sidebar.sidebar-positionInfo .sidebar__ticker__info-image {
  max-width: 40px;
}
.sidebar.sidebar-positionInfo .sidebar__ticker__info__stub {
  background: #F0F1F2;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar.sidebar-positionInfo .sidebar__ticker__info__stub-title {
  font-weight: 600;
}
.sidebar.sidebar-positionInfo .sidebar__ticker__info__description {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.sidebar.sidebar-positionInfo .sidebar__ticker__info__description-title {
  line-height: 24px;
  font-size: 24px;
  font-weight: 700;
}
.sidebar.sidebar-positionInfo .sidebar__ticker__info__description-name {
  line-height: 14px;
  font-size: 14px;
  font-weight: 600;
  color: #98A2B3;
}
.sidebar.sidebar-positionInfo .sidebar__ticker__prices {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}
.sidebar.sidebar-positionInfo .sidebar__ticker__prices-main {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}
.sidebar.sidebar-positionInfo .sidebar__ticker__prices__change {
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: 600;
  line-height: 16px;
}
.sidebar.sidebar-positionInfo .sidebar .skeleton-asset-info {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}
.sidebar.sidebar-positionInfo .sidebar .skeleton-asset-info__titles {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sidebar.sidebar-positionInfo .sidebar__deals-title {
  font-size: 18px;
  margin-bottom: 16px;
}
.sidebar.sidebar-positionInfo .sidebar__deals__table {
  max-height: 350px;
  overflow-y: auto;
  padding-right: 10px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__btns {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-complexOrderDetails .sidebar__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__info__ticker {
  display: flex;
  align-items: center;
  gap: 8px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__info__ticker-icon {
  max-width: 40px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__info__ticker__stub {
  background: #F0F1F2;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar.sidebar-complexOrderDetails .sidebar__info__ticker__stub-title {
  font-weight: 600;
}
.sidebar.sidebar-complexOrderDetails .sidebar__info__ticker__description {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__info__ticker__description-title {
  line-height: 24px;
  font-size: 24px;
  font-weight: 700;
}
.sidebar.sidebar-complexOrderDetails .sidebar__info__ticker__description-name {
  line-height: 14px;
  font-size: 14px;
  font-weight: 600;
  color: #98A2B3;
}
.sidebar.sidebar-complexOrderDetails .sidebar__info-date {
  line-height: 14px;
  font-size: 14px;
  font-weight: 600;
  color: #98A2B3;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__wrapper-indent {
  padding-left: 33px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__wrapper-help-text {
  line-height: 14px;
  font-size: 14px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__wrapper-main {
  background: #FFFFFF;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__wrapper-main .complex-order-card {
  z-index: 10;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__wrapper-main-noHelpText {
  padding-top: 20px;
  margin-top: -20px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card {
  border: 1px solid #ECECEC;
  border-radius: 8px;
  cursor: pointer;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 11px 15px;
  border-radius: 8px 8px 0 0;
  background: #F0F1F2;
  position: relative;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header .complex-order-big-arrow {
  position: absolute;
  left: -27px;
  top: -50%;
  transform: translateY(-50%);
  width: 20px;
  z-index: 0;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header .complex-order-arrow-oco-top {
  width: 20px;
  position: absolute;
  left: -27px;
  top: 16px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header .complex-order-arrow-oco-bottom {
  width: 20px;
  position: absolute;
  left: -27px;
  bottom: 12px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header-title {
  font-weight: 600;
  line-height: 16px;
  color: #98A2B3;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header-side {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #98A2B3;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header-green {
  background: #12B76A;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header-green .complex-order-card__header-title,
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header-green .complex-order-card__header-side {
  color: #FFFFFF;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header-red {
  background: #F04438;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header-red .complex-order-card__header-title,
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__header-red .complex-order-card__header-side {
  color: #FFFFFF;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__body {
  border-radius: 0 0 8px 8px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #FFFFFF;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__body__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__body__row-title {
  line-height: 16px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__body__row-value {
  font-weight: 600;
  line-height: 16px;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__body__row-value-green {
  color: #12B76A;
}
.sidebar.sidebar-complexOrderDetails .sidebar__cards .complex-order-card__body__row-value-red {
  color: #EA3D30;
}
.sidebar.sidebar-changeComplexOrder .sidebar__btns {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-changeComplexOrder .sidebar__info {
  margin-top: 40px;
  border-radius: 8px;
  padding: 16px 20px;
  background: #F9FAFB;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__info__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__info__item-title {
  line-height: 20.8px;
  color: #505055;
}
.sidebar.sidebar-changeComplexOrder .sidebar__info__item-value {
  line-height: 20.8px;
  font-weight: 600;
}
.sidebar.sidebar-changeComplexOrder .sidebar__inputs {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__inputs__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__inputs__item-title {
  font-weight: 500;
  line-height: 16px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__inputs__item-line {
  width: 100%;
  height: 1px;
  background: #F2F2F2;
}
.sidebar.sidebar-changeComplexOrder .sidebar__slTp {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
  margin-top: 40px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__slTp__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sidebar.sidebar-changeComplexOrder .sidebar__slTp__column__title {
  display: flex;
  align-items: center;
  gap: 4px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__slTp__column__title-value {
  font-weight: 500;
  line-height: 20.8px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__slTp__column__counters {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__slTp__column__types {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  gap: 16px;
}
.sidebar.sidebar-changeComplexOrder .sidebar__slTp__column__types-title {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: #98A2B3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}
.sidebar.sidebar-confirmOrder .sidebar__btns {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}
.sidebar.sidebar-confirmOrder .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-confirmOrder .sidebar__info {
  margin-top: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.sidebar.sidebar-confirmOrder .sidebar__info__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.sidebar.sidebar-confirmOrder .sidebar__info__item-title {
  line-height: 20.8px;
  color: #505055;
}
.sidebar.sidebar-confirmOrder .sidebar__info__item-value {
  line-height: 20.8px;
  font-weight: 600;
}
.sidebar.sidebar-confirmCreateOrder .sidebar__btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}
.sidebar.sidebar-confirmCreateOrder .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-confirmCreateOrder .sidebar__info {
  margin-top: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sidebar.sidebar-confirmCreateOrder .sidebar__info__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.sidebar.sidebar-confirmCreateOrder .sidebar__info__item-title {
  line-height: 1;
  color: #98A2B3;
}
.sidebar.sidebar-confirmCreateOrder .sidebar__info__item-value {
  line-height: 1;
  font-weight: 500;
  color: #212129;
}
.sidebar.sidebar-confirmCreateOrder .sidebar__info__line {
  height: 1px;
  background: #F2F2F2;
  width: 100%;
}
.sidebar.sidebar-cancelOrder .sidebar__btns {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}
.sidebar.sidebar-cancelOrder .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-cancelOrder .sidebar__content {
  margin-top: 40px;
  gap: 0;
}
.sidebar.sidebar-cancelOrder .sidebar__content-text {
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
}
.sidebar.sidebar-cancelOrder .sidebar__content__order {
  border: 1px solid #ECECEC;
  border-radius: 8px;
  margin-top: 40px;
}
.sidebar.sidebar-cancelOrder .sidebar__content__order__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #ECECEC;
}
.sidebar.sidebar-cancelOrder .sidebar__content__order__header-symbol {
  font-weight: 600;
  line-height: 16px;
}
.sidebar.sidebar-cancelOrder .sidebar__content__order__header-side {
  font-weight: 600;
  line-height: 14px;
}
.sidebar.sidebar-cancelOrder .sidebar__content__order__header-side-green {
  color: #12B76A;
}
.sidebar.sidebar-cancelOrder .sidebar__content__order__header-side-red {
  color: #EA3D30;
}
.sidebar.sidebar-cancelOrder .sidebar__content__order__data {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
}
.sidebar.sidebar-cancelOrder .sidebar__content__order__data__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.sidebar.sidebar-cancelOrder .sidebar__content__order__data__row-title {
  line-height: 16px;
}
.sidebar.sidebar-cancelOrder .sidebar__content__order__data__row-value {
  font-weight: 600;
  line-height: 16px;
}
.sidebar.sidebar-confirmCreateOrderOption .sidebar__btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}
.sidebar.sidebar-confirmCreateOrderOption .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-confirmCreateOrderOption .sidebar__info {
  margin-top: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sidebar.sidebar-confirmCreateOrderOption .sidebar__info__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.sidebar.sidebar-confirmCreateOrderOption .sidebar__info__item-title {
  line-height: 1;
  color: #98A2B3;
}
.sidebar.sidebar-confirmCreateOrderOption .sidebar__info__item-value {
  line-height: 1;
  font-weight: 500;
  color: #212129;
}
.sidebar.sidebar-confirmCreateOrderOption .sidebar__info__line {
  height: 1px;
  background: #F2F2F2;
  width: 100%;
}
.sidebar.sidebar-confirmCreateOrderOption .sidebar__info__description {
  border-radius: 8px;
  border: 1px solid #EEC02F;
  padding: 10px 12px;
  margin-top: 15px;
  line-height: 16px;
}
.sidebar.sidebar-confirmCreateOrderOption .sidebar__info__description-value {
  font-size: 14px;
  font-weight: 500;
}
.sidebar.sidebar-closePositionOption .sidebar__btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}
.sidebar.sidebar-closePositionOption .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-closePositionOption .sidebar__info {
  margin-top: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sidebar.sidebar-closePositionOption .sidebar__info__header {
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar.sidebar-closePositionOption .sidebar__info__header-title {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.sidebar.sidebar-closePositionOption .sidebar__info__header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.sidebar.sidebar-closePositionOption .sidebar__info__header__content-text {
  color: #98A2B3;
  font-size: 14px;
}
.sidebar.sidebar-closePositionOption .sidebar__info__body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.sidebar.sidebar-closePositionOption .sidebar__info__body .form-control {
  margin: 0;
}
.sidebar.sidebar-closePositionOption .sidebar__info__body .qty-mark {
  font-size: 16px;
  font-weight: 500;
}
.sidebar.sidebar-executePositionOption .sidebar__btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}
.sidebar.sidebar-executePositionOption .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-executePositionOption .sidebar__info {
  margin-top: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sidebar.sidebar-executePositionOption .sidebar__info__header {
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar.sidebar-executePositionOption .sidebar__info__header-title {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.sidebar.sidebar-executePositionOption .sidebar__info__header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.sidebar.sidebar-executePositionOption .sidebar__info__header__content-text {
  color: #98A2B3;
  font-size: 14px;
}
.sidebar.sidebar-executePositionOption .sidebar__info__body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.sidebar.sidebar-executePositionOption .sidebar__info__body .qty-mark {
  font-size: 16px;
  font-weight: 500;
}
.sidebar.sidebar-executePositionOption .sidebar__info__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.sidebar.sidebar-executePositionOption .sidebar__info__item-title {
  font-size: 16px;
  font-weight: 500;
  color: #363F41;
}
.sidebar.sidebar-executePositionOption .sidebar__info__item-value {
  font-size: 18px;
  font-weight: 500;
  color: #212129;
}
.sidebar.sidebar-executePositionOption .sidebar__info__item .form-control {
  margin: 0;
}
.sidebar.sidebar-executePositionOption .sidebar__info__item__result {
  width: 100%;
  padding: 10px 12px;
  border-radius: 8px;
  background: #F0F1F2;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.sidebar.sidebar-executePositionOption .sidebar__info__item__result-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar.sidebar-executePositionOption .sidebar__info__item__result-row:first-child .sidebar__info__item__result-row-title {
  font-size: 16px;
  font-weight: 600;
  color: #212129;
}
.sidebar.sidebar-executePositionOption .sidebar__info__item__result-row:first-child .sidebar__info__item__result-row-value {
  font-size: 16px;
  font-weight: 600;
  color: #212129;
}
.sidebar.sidebar-executePositionOption .sidebar__info__item__result-row-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #98A2B3;
}
.sidebar.sidebar-executePositionOption .sidebar__info__item__result-row-value {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #98A2B3;
}
.sidebar.sidebar-confirmExecutePositionOption .sidebar__btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}
.sidebar.sidebar-confirmExecutePositionOption .sidebar__btns .btn {
  width: 100%;
}
.sidebar.sidebar-confirmExecutePositionOption .sidebar__info {
  margin-top: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sidebar.sidebar-confirmExecutePositionOption .sidebar__info__body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.sidebar.sidebar-confirmExecutePositionOption .sidebar__info__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.sidebar.sidebar-confirmExecutePositionOption .sidebar__info__item-title {
  line-height: 1;
  color: #98A2B3;
}
.sidebar.sidebar-confirmExecutePositionOption .sidebar__info__item-value {
  line-height: 1;
  font-weight: 500;
  color: #212129;
}
.sidebar.sidebar-confirmExecutePositionOption .sidebar__info__item__description {
  border-radius: 8px;
  border: 1px solid #EEC02F;
  padding: 10px 12px;
  margin-top: 15px;
  line-height: 16px;
}
.sidebar.sidebar-confirmExecutePositionOption .sidebar__info__item__description-value {
  font-size: 14px;
  font-weight: 500;
}

.mat-datepicker-content {
  border-radius: 12px !important;
}
.mat-datepicker-content .mat-calendar {
  padding: 10px !important;
}

.mat-calendar-header {
  padding: 0 !important;
}

.mat-calendar-controls {
  margin: 0 !important;
}

.mat-calendar-content {
  padding: 0 !important;
}

.mat-calendar-body-cell-content:hover {
  background: #F4F4F4 !important;
}

.mat-calendar-body-selected {
  background: #EEB501 !important;
}
.mat-calendar-body-selected:hover {
  background: #F3BD12 !important;
}

.cdk-keyboard-focused {
  background: #F4F4F4 !important;
}
.cdk-keyboard-focused:hover {
  background: #F4F4F4 !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #DBE0E8 !important;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical):hover {
  border: none !important;
}

.mat-calendar-body-cell:hover .mat-calendar-body-cell-content mat-focus-indicator {
  background: #F4F4F4 !important;
}

.mat-calendar-body-cell :not(.mat-calendar-body-disabled) :hover > .mat-calendar-body-cell-content :not(.mat-calendar-body-selected) :not(.mat-calendar-body-comparison-identical) {
  background: #F4F4F4 !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: #F4F4F4 !important;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: none !important;
}