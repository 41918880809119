// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '../../../node_modules/@angular/material/index' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$new-trading-primary: mat.define-palette(mat.$indigo-palette);
$new-trading-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$new-trading-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$new-trading-theme: mat.define-light-theme((
	color: (
		primary: $new-trading-primary,
		accent: $new-trading-accent,
		warn: $new-trading-warn,
	)
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($new-trading-theme);

.mat-mdc-dialog-container {
	--mdc-dialog-container-elevation-shadow: $default-shadow !important;
	--mdc-dialog-container-shape: 20px !important;

	.mdc-dialog__surface {
		overflow-y: inherit !important;
	}
}
