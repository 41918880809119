@import "src/core/scss/variables";

.skin-visible {
	content: '';
	position: fixed;
	left: 0;
	top: 0;
	z-index: $z-index-3;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .2);
}
