@import "src/core/scss/variables";

.profitability {

	&-success {
		color: $success-text !important;
	}

	&-danger {
		color: $danger-text !important;
	}

	&-gray {
		color: $dark-text !important;
	}
}
