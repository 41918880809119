// default
$userFont: 'sf-pro-ex', sans-serif;
$systemFont: -apple-system, BlinkMacSystemFont, Arial, sans-serif; // System Font Family
$line-height-title: 1.2;
$default-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);

// font size
$display-2xl: 72px;
$display-xl: 60px;
$display-lg: 48px;
$display-md: 36px;
$display-sm: 30px;
$display-xs: 24px;

$text-xl: 20px;
$text-lg: 18px;
$text-md: 16px;
$text-sm: 14px;
$text-xs: 12px;

$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 40;
$z-index-5: 50;


// colors
$black-text: #212129;
$light-text: #FFFFFF;
$text-black: #363F41;
$primary-text: #EEB501;
$dark-text: #98A2B3;
$light-border: #F2F2F2;
$dark-border: #DBE0E8;
$gray-background: #F0F1F2;
$white-background: #FFFFFF;
$light-background: #F9FAFB;
$success-text: #12B76A;
$success-background: #12B76A;
$text-green: #63BE37;
$danger-text: #EA3D30;
$danger-background: #EA3D30;
$danger-border: #F04438;
$danger-background: #F04438;
$primary-background: #F7CD47;
$danger-icon: #E73D31;
$black-primary: #212129;
$secondary-text: #3A97FB;
$text-grey: #425466;
$text-light-grey: #7F94A9;
$primary-border: #EEC02F;
$gray-border: #ECECEC;
$warning-text: #F3BD12;
$white-text: #FFFFFF;
$lines-grey-light: #DFE1E8;
$back-grey-middle: #EEF3F5;
$text-grey-for-black: #9191B5;
$text-grey-for-white: #7E8CA0;
$text-red-web: #E90007;
$dark-grey: #505055;
$light-grey: #F4F4F4;
$yellow-on-black: #FED660;
$gray-shadow: #E3E5EE;
$text-link: #4687F3;
$dark-icon: #A5AEBD;
$back-yellow: #F3CC50;
$back-red: #F8474C;

// custom colors
$grayTextColor: #BDBDBD;
$infoColor: #2F86EB;
$warningColor: #FFC021;
