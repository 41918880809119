.overlay {
	background: rgba(#000, 0.3);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	outline: none;
}


.fade {
	transition: opacity .15s linear;
}
