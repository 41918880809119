@import "src/core/scss/variables";

.switch {
	position: relative;
	display: inline-block;
	width: 44px;
	height: 24px;

	.switch-input {
		display: none;

		&:checked {

			+ {
				.switch-label {
					background: $success-background;

					&::before {
						transform: translateX(20px);
					}
				}
			}
		}
	}

	.switch-label {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $gray-background;
		border-radius: 34px;
		cursor: pointer;
		transition: background 0.3s;

		&::before {
			content: "";
			position: absolute;
			width: 18px;
			height: 18px;
			border-radius: 100%;
			left: 3px;
			top: 3px;
			background: $white-background;
			transition: transform 0.3s;
		}
	}

	&.switch-danger {

		.switch-input {

			&:checked {

				+ {
					.switch-label {
						background: $danger-background;
					}
				}
			}
		}
	}
}
